const responsive = "vertical";
const tableBodyMaxHeight = "";

export const tableDefaultOptions = {
  download: false,
  print: false,
  filter: false,
  selectableRows: 'none',
  rowsPerPageOptions: [10, 25, 50],
  responsive,
  tableBodyMaxHeight,
  rowHover: false,


  sortOrder: {
    name: "user_id",
    direction: "desc",
  },
  
  textLabels: {
    body: {
      noMatch: "Desculpe, não encontramos nada",
      toolTip: "Ordenar",
    },
    selectedRows: {
      text: 'linha(s) selecionada'
    },
    pagination: {
      next: "Próxima página",
      previous: "Página anterior",
      rowsPerPage: "Linhas por página:",
      displayRows: "de",
    },
    toolbar: {
      search: "Procurar",
      viewColumns: "Visualizar colunas",
      filterTable: "Filtrar tabela",
    },
    viewColumns: {
      title: "Mostrar colunas",
      titleAria: "Mostrar/Esconder colunas da tabela",
    },
  }
};

export const tableDefaultColumns = [
  {
    name: "id",
    label: "Número",
  },
  {
    name: "name",
    label: "Nome",
  },
  {
    name: "email",
    label: "Email",
  },
  {
    name: "website",
    label: "Website",
  },
  {
    name: "phone",
    label: "Telefone",
  },
  {
    name: "details",
    label: "Detalhes",
  }
];
