import React from "react";
import {
  api,
  ENV,
  routeUrlAuthenticated,
} from "../../services/api";

import { Button } from "@material-ui/core";
import { useIsMobile } from "../../hooks/useIsMobile";
import { Alert, Box } from "../../components";
import { handlingErrors } from "../../utils";

export const AccessPortalComponent = (props) => {
  const [isAlert, setIsAlert] = React.useState({
    open: false,
    message: "",
    status: "",
  });
  const { value, isV2 } = props;

  const isMobile = useIsMobile();
  const product = sessionStorage.getItem("product");

  const handleCloseSnackbar = () =>
    setIsAlert((prevAlert) => ({ ...prevAlert, open: false }));

  const handleOpenDialog = async () => {
    const apiURL = isV2 ? `${routeUrlAuthenticated}/${value.cnpj}` :
      `/client/${value.ecid}/url-authenticated-jovian`

    await api
      .get(apiURL)
      .then((response) => {
        const { url_authenticated: path, platform } = response.data;

        const isLegacy = isV2 && platform === 'legacy' ? '_LEGACY' : '';
        const productKey = `${product.toUpperCase()}${isLegacy}_URL`
        const domain = ENV[productKey]

        window.open(`${domain}${path}`)

      })
      .catch((error) => {
        handlingErrors(error, setIsAlert);
      });
  };

  return (
    <Box display="flex" justifyContent={isMobile ? "flex-start" : "center"}>
      <Button color="primary" onClick={handleOpenDialog}>
        Acessar Portal
      </Button>
      <Alert
        component="temporary"
        open={isAlert.open}
        severity={isAlert.status}
        message={isAlert.message}
        onClose={handleCloseSnackbar}
      />
    </Box>
  );
};
