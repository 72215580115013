import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  paragraph: {
    fontWeight: 400,
  },
  img: {
    width: '250px',
    height: 'auto',
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '350px',
    backgroundColor: 'transparent'
  }
}));
