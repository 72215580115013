import React from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { formOptions } from "./props";
import { Controls } from "../../../components/controls";
import { Link as MaterialLink, IconButton, InputAdornment } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { Button } from "@material-ui/core";
import { Alert, Box, Icon, Paper, Typography } from "../../../components";
import { api, routeEditPassword } from "../../../services/api";
import { handlingSuccess, handlingErrors } from "../../../utils";

import { useStyles } from "../style";

export const PasswordPageComponent = () => {
  const [currentShowPassword, setCurrentShowPassword] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = React.useState(false);
  const { handleSubmit, control } = useForm(formOptions);
  const classes = useStyles();

  const [isAlert, setIsAlert] = React.useState({
    open: false,
    message: "",
    status: "",
  });

  const handleCloseSnackbar = () =>
    setIsAlert((prevAlert) => ({ ...prevAlert, open: false }));

  const handleClickCurrentShowPassword = () => setCurrentShowPassword(!currentShowPassword);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () => setConfirmShowPassword(!showConfirmPassword);

  const onSubmit = async (data) => {
    const { currentPassword, password, confirmPassword } = data;

    api
      .post(routeEditPassword, {
        senhaAtual: currentPassword,
        senha: password,
        confirmaSenha: confirmPassword,
      })
      .then((response) => {
        handlingSuccess(response, setIsAlert);
      })
      .catch((error) => {
        handlingErrors(error, setIsAlert);
      });
  };

  return (
    <Box display="flex" flexDirection="column">
      <Helmet>
        <title>Editar senha | Originador </title>
      </Helmet>
      {isAlert.open && (
        <Alert
          component="temporary"
          open={isAlert.open}
          severity={isAlert.status}
          message={isAlert.message}
          onClose={handleCloseSnackbar}
        />
      )}
      <Box display="flex" justifyContent="initial">
        <Typography fontWeight="bold">Editar senha</Typography>
      </Box>
      <Paper width="100%" mt={4}>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" flexDirection="column" my={2}>
            <Box maxWidth="500px">
              <Controls.Input
                name="currentPassword"
                label="Senha atual"
                placeholder="Digite sua senha atual"
                size="medium"
                type={currentShowPassword ? "text" : "password"}
                control={control}
                required
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Alternar a visibilidade da senha"
                        onClick={handleClickCurrentShowPassword}
                      >
                        {currentShowPassword ? <Icon name="showPassword" /> : <Icon name="hidePassword" />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Box mt={1} mb={2}>
                <MaterialLink component={Link} to="/auth/forgot" underline="always" onClick={() => sessionStorage.clear()}>
                  Esqueceu senha?
                </MaterialLink>
              </Box>
              <Controls.Input
                name="password"
                label="Senha"
                placeholder="Digite sua nova senha"
                size="medium"
                type={showPassword ? "text" : "password"}
                control={control}
                required
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Alternar a visibilidade da senha"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <Icon name="showPassword" /> : <Icon name="hidePassword" />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Controls.Input
                name="confirmPassword"
                label="Confirma senha"
                placeholder="Confirme sua nova senha"
                size="medium"
                type={showConfirmPassword ? "text" : "password"}
                control={control}
                required
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Alternar a visibilidade da senha"
                        onClick={handleClickShowConfirmPassword}
                      >
                        {showConfirmPassword ? <Icon name="showPassword" /> : <Icon name="hidePassword" />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Button
                color="primary"
                variant="contained"
                type="submit"
                className={classes.buttonSubmitPassword}
              >
                Salvar alterações
              </Button>
            </Box>
          </Box>
        </form>
      </Paper>
    </Box>
  );
};
