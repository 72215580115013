import { makeStyles } from "@material-ui/core";
import { theme } from "../../theme";


function getColorStatus(status){
  switch (status) {
    case "approved":
      return theme.palette.success.main;
    case "declined":
      return theme.palette.error.main;
    default:
      return theme.palette.warning.main;
  }
} 

export const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: props => getColorStatus(props.status),
  },
  buttonReproval: {
    marginRight: theme.spacing(1),
  },
  buttonApproval: {
    marginLeft: theme.spacing(1)
  },
  textField: {
    whiteSpace: "nowrap"
  }
}));
