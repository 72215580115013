import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export const validationSchema = Yup.object().shape({
  password: Yup.string()
  .required('O campo senha é obrigatório')
  .min(8, "Por favor digite uma senha com no mínimo 8 caracteres."),
  confirmPassword: Yup.string()
  .required('O campo confirmar senha é obrigatório')
  .oneOf([Yup.ref('password'), null], 'As senhas não são iguais.'),
});

export const formOptions = {
  resolver: yupResolver(validationSchema),
  defaultValues: {
    password: "",
    confirmPassword: "",
  },
};
