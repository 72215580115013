import { Box, Typography } from "../../../../../components";
import { formatValue } from "../../../../../utils";

export const RegistrationInformationComponent = (props) => {
  const { cpf, email, name, telephone } = props.dataRegistration;

  return (
    <Box display="flex" flexDirection="column" my={2}>
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Typography variant="body1" fontWeight={700}>
          Nome:
        </Typography>
        <Typography>{formatValue(name, "captalize")}</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Typography variant="body1" fontWeight={700}>
          CPF:
        </Typography>
        <Typography>{formatValue(cpf, "cpf")}</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Typography variant="body1" fontWeight={700}>
          Email:
        </Typography>
        <Typography>{email ? email : "-"}</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Typography variant="body1" fontWeight={700}>
          Telefone:
        </Typography>
        <Typography>{formatValue(telephone, "telephone")}</Typography>
      </Box>
    </Box>
  );
};
