import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: '100%',
      marginTop: '1rem',
      marginBottom: '1rem'
    }
  };
});
