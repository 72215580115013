import React from "react";
import { Controller } from "react-hook-form";
import {
  FormControl,
  FormControlLabel,
  Checkbox as MaterialCheckbox,
  FormHelperText,
} from "@material-ui/core";

export const CheckboxComponent = React.forwardRef((props, ref) => {
  const { label, control, name } = props;

  return (
    <Controller
      control={control}
      name={name}
      ref={ref}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { error },
      }) => {
        return (
          <FormControl>
            <FormControlLabel
              control={
                <MaterialCheckbox
                  name={name}
                  inputRef={ref}
                  color="primary"
                  checked={value}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              }
              label={label}
            />
            <FormHelperText error>{error?.message}</FormHelperText>
          </FormControl>
        );
      }}
    />
  );
});
