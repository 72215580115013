import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Box } from "../Box";
import { useStyles } from "./style";

export const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      width="100%"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box px={1} py={2} width="100%">
          {children}
        </Box>
      )}
    </Box>
  );
};

export const TabsComponent = (props) => {
  const { match, history, mainRoute } = props;
  const { params } = match;
  const { tab } = params;
  const classes = useStyles();

  const tabNameToIndex = Object.assign(
    ...props.tabs.map((item, index) => ({ [index]: item.routerName }))
  );
  // { extract :0,  credit: 1}
  const indexToTabName = Object.assign(
    ...props.tabs.map((item, index) => ({ [item.routerName]: index }))
  );
  // { 0 : extract, 1: credit}

  const [selectedTab, setSelectedTab] = React.useState(
    props.selectedIndex || indexToTabName[tab]
  );

  const handleChange = (event, newValue) => {
    history.push(`/${mainRoute}/${tabNameToIndex[newValue]}`);
    setSelectedTab(newValue);
  };

  return (
    <>
      <Box position="static" mt={1} width="100%">
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          className={classes.tabs}
        >
          {props.tabs.map((item, index) => (
            <Tab key={index} label={item.title} />
          ))}
        </Tabs>
      </Box>

      {props.tabs.map((item, index) => (
        <TabPanel key={index} value={selectedTab} index={index}>
          {item.content}
        </TabPanel>
      ))}
    </>
  );
};
