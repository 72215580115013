import React from "react";
import { isCPFOrCNPJ } from "brazilian-values";
import { api, routeExistsUser } from "../../../../../services/api";
import { handlingErrors } from "../../../../../utils";
import { Link as MaterialLink, IconButton, InputAdornment } from "@material-ui/core";
import { Icon, Typography } from "../../../../../components";
import { Controls } from "../../../../../components/controls";

export const InformationStepCompponent = (props) => {
  const [isAlert, setIsAlert] = React.useState({
    open: false,
    message: "",
    status: "",
  });
  const [currentShowPassword, setCurrentShowPassword] = React.useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = React.useState(false);

  const handleClickCurrentShowPassword = () => setCurrentShowPassword(!currentShowPassword);
  const handleClickShowConfirmPassword = () => setConfirmShowPassword(!showConfirmPassword);

  const checkCnpj = async (event) => {
    const valueCnpj = event.target.value;
    const newValueCnpj = valueCnpj.replace(/[^0-9]/g, "");

    if (newValueCnpj === "") return;

    if (isCPFOrCNPJ(newValueCnpj) === false) return;

    await api
      .get(`${routeExistsUser}/${newValueCnpj}`)
      .then((response) => {
        const { exists, message, status } = response.data;
        if (exists === false) {
          handleAlert();
          setIsAlert((prevAlert) => ({
            ...prevAlert,
            open: true,
            message: message,
            status: status,
          }));
        } else {
          handleAlert();
          setIsAlert((prevAlert) => ({
            ...prevAlert,
            open: false,
            message: "",
            status: "",
          }));
        }
      })
      .catch((error) => {
        handleAlert();
        handlingErrors(error, setIsAlert);
      });
  };

  const handleAlert = () => props.onHandleAlert(isAlert);

  return (
    <>
      <Controls.InputMask
        name="cnpjCpf"
        label="CNPJ ou CPF"
        placeholder="Digite seu CNPJ ou CPF"
        fullWidth
        required
        mask={["999.999.999-99", "99.999.999/9999-99"]}
        onBlur={checkCnpj}
      />
      <Controls.Input
        name="fullName"
        label="Nome Completo"
        placeholder="Digite seu nome completo"
        fullWidth
        required
      />
      <Controls.Input
        name="username"
        label="Usuario"
        placeholder="Digite seu usuario"
        fullWidth
        required
      />
      <Controls.Input
        name="email"
        label="E-mail"
        placeholder="Digite seu e-mail"
        fullWidth
        required
      />
      <Controls.InputMask
        name="cellphone"
        label="Celular"
        placeholder="Digite seu celular"
        fullWidth
        required
        mask={["(99) 99999-9999"]}
      />
      <Controls.Input
        name="password"
        label="Senha"
        placeholder="Digite sua senha"
        type={currentShowPassword ? "text" : "password"}
        fullWidth
        required
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Alternar a visibilidade da senha"
                onClick={handleClickCurrentShowPassword}
              >
                {currentShowPassword ? (
                  <Icon name="showPassword" />
                ) : (
                  <Icon name="hidePassword" />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Controls.Input
        name="confirmPassword"
        label="Confirme sua senha"
        placeholder="Digite sua senha"
        type={showConfirmPassword ? "text" : "password"}
        fullWidth
        required
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Alternar a visibilidade da senha"
                onClick={handleClickShowConfirmPassword}
              >
                {showConfirmPassword ? (
                  <Icon name="showPassword" />
                ) : (
                  <Icon name="hidePassword" />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Controls.Checkbox
        name="acceptTerms"
        label={
          <Typography variant="body2" component="p" color="textSecondary">
            Aceito o &nbsp;
            <MaterialLink
              href="https://app.tomatico.com/docs/termos_condicoes.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              termo de uso e política de privacidade de uso.
            </MaterialLink>
          </Typography>
        }
      />
    </>
  );
};
