import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Box } from "../../../components";
import { formatToBRL } from "brazilian-values";
import { months } from "./props";

export const LineChartComponent = (props) => {
  return (
    <Box display="flex" width="100%" height={300}>
      <ResponsiveContainer>
        <AreaChart
          data={props.data.length === 0 ? months : props.data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <Tooltip
            formatter={(value) => {
              return formatToBRL(value);
            }}
          />

          <defs>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#32AFDC" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#32AFDC" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey={props.dataKeyXAxis} />
          <YAxis
            tickFormatter={(tick) => {
              return tick.toLocaleString("pt-BR");
            }}
          />
          <Tooltip />
          <Area
            type="monotone"
            dataKey={props.dataKeyArea}
            stroke="#32AFDC"
            fillOpacity={1}
            fill="url(#colorPv)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
};
