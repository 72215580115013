import React from 'react';
import { Link as MaterialLink} from "@material-ui/core";
import { Box, Progress, Typography  } from "../../../components";
import { DownloadCCB } from '../../../layout';
import { formatToBRL } from "brazilian-values";
import { captalizeString, formatDate } from "../../../utils";

export const tableOperationsWalletColumns = [
  {
    name: "id",
    label: "ID",
  },
  {
    name: "companyName",
    label: "Estabalecimento comercial",
    options: {
      customBodyRender: (name) => {
        return (
          <MaterialLink underline="always" color="primary">
            {captalizeString(name)}
          </MaterialLink>
        );
      },
    },
  },
  {
    name: "hiringDate",
    label: "Data de contratação",
    options: {
      customBodyRender: (value) => value ? formatDate(value) : "-",
    },
  },
  {
    name: "dueDate",
    label: "Data estimada de vencimento",
    options: {
      customBodyRender: (value) => value ? formatDate(value) : "-",
    },
  },
  {
    name: "operationValue",
    label: "Valor da operação",
    options: {
      customBodyRender: (value) => value ? formatToBRL(value) : "-",
    },
  },
  {
    name: "totalAmountOwed",
    label: "Valor total devido",
    options: {
      customBodyRender: (value) => value ? formatToBRL(value) : "-",
    },
  },
  {
    name: "amountPaid",
    label: "Valor pago",
    options: {
      customBodyRender: (value) => value ? formatToBRL(value) : "-",
    },
  },
  {
    name: "percentage_paid",
    label: "Status",
    options: {
      sort: false,
      customBodyRender: (value) => {
        return (
          <Box display="flex" flexDirection="column">
            <Box display="flex" justifyContent="space-between">
              <Box width="100%" minWidth="80px">
                <Progress
                  name="linear"
                  color="secondary"
                  style={{ height: "10px", borderRadius: "25px" }}
                  variant="determinate"
                  value={value.percentage_paid ? value.percentage_paid : 0}
                />
              </Box>
              <Box ml={1}>
                <Typography variant="body2">{value.percentage_paid ? value.percentage_paid : 0}%</Typography>
              </Box>
            </Box>
          </Box>
        );
      },
    },
  },
  {
    name: "link",
    label: " ",
    options: {
      sort: false,
      customBodyRender: (value) => <DownloadCCB operationId={value} />,
    },
  },
];
