import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
    },
    content: {
      minHeight: "100vh",
      marginTop: (prop) => prop.marginTop,
      [theme.breakpoints.up("sm")]: {
        width: (prop) => `calc(100% - ${prop.drawerWidth})`,
        marginLeft: (prop) => prop.marginLeft,
      },
    },
    container: {
      flexGrow: 1,
      minHeight: "100vh",
    },
  };
});
