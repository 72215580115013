import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.primary.dark,
    zIndex: 1000,
    alignSelf: "flex-end",
    [theme.breakpoints.up("sm")]: {
      width: (prop) => `calc(100% - ${prop.drawerWidth})`,
      marginLeft: (prop) => prop.drawerWidth,
    },
  },
  link: {
    padding: theme.spacing(1),
    fontSize: "16px",
    color: "#FFFFFF",
  },
}));
