import React from "react";
import {
  Switch,
  Route,
  Redirect,
  withRouter
} from "react-router-dom";
import {
  Hidden,
  Grid,
  CssBaseline,
  Container,
} from "@material-ui/core";
import { Navbar, Footer, Topbar, Drawer, Cookies, DialogRegisterCustomer } from "../layout";

import { useIsMobile } from "../hooks/useIsMobile";
import { Alert, Box } from "../components";
import { AuthPage } from "./Auth";
import { CustomersPage } from "./Customers";
import { NotFoundPage } from "./NotFound";
import { OperationsPage } from "./Operations";
import { OldOperationsPage } from "./OldOperations";
import { OriginatorPage } from "./Originator";
// import { HomePage } from "./Home";
import { useStyles } from "./style";
import { PrivateRoute } from "../routes";
import { GlobalContext } from "../context";
import { EditPage } from "./Edit";
import { handlingErrors, handlingSuccess } from "../utils";

export const PagesComponent = withRouter((props) => {
  const [open, setOpen] = React.useState(false);
  const [isAlert, setIsAlert] = React.useState({
    open: false,
    message: "",
    status: "",
  });
 
  const url = props.location.pathname;
  const isMobile = useIsMobile();
  const global = React.useContext(GlobalContext);

  const handleOpenDrawer = () => {
    setOpen(!open);
  };

  const handleOpenDialog = () => {
    global.setOpenDialogAddCustomer(true);
  };

  const handleCloseDialog = () => {
    global.setOpenDialogAddCustomer(false);
  };



  const handleCloseSnackbar = () =>
    setIsAlert((prevAlert) => ({ ...prevAlert, open: false }));


  const classes = useStyles({
    marginTop: isMobile ? (url.includes("/auth") ? "32px" : "64px") : "64px",
    drawerWidth: url.includes("/auth") ? "0px" : "200px",
    marginLeft: url.includes("/auth") ? "auto" : "200px",
  });

  return (
    <Box className={classes.root}>
      {isAlert.open && (
        <Alert
          component="temporary"
          open={isAlert.open}
          severity={isAlert.status}
          message={isAlert.message}
          onClose={handleCloseSnackbar}
        />
      )}
      <CssBaseline />
      <Navbar
        drawerWidth={url.includes("/auth") ? "0px" : "200px"}
        elevation={url.includes("/auth") ? 4 : 0}
        openDrawer={handleOpenDrawer}
        {...props}
      />
      {url.includes("/auth") ? (
        ""
      ) : (
        <>
          <Hidden xsDown>
            <Drawer variant="permanent" open={true} url={url} />
          </Hidden>
          <Hidden smUp>
            <Drawer
              variant="temporary"
              open={open}
              onClose={handleOpenDrawer}
            />
          </Hidden>
        </>
      )}
      <Box component="main" className={classes.content}>
        <Container maxWidth="lg" className={classes.container}>
          <Grid container>
            {url.includes("/auth") ? (
              ""
            ) : (
              <Topbar
                handleClickTopbarButton={handleOpenDialog}
                drawerWidth={url.includes("/auth") ? "0px" : "200px"}
              />
            )}
            <DialogRegisterCustomer
              open={global.openDialogAddCustomer}
              onClose={handleCloseDialog}
              onCloseSnackbar={handleCloseSnackbar}
              access={sessionStorage.getItem('access_type')}
              onHandligError={(resp) => handlingErrors(resp,setIsAlert)}
              onHandligSuccess={(resp) => handlingSuccess(resp,setIsAlert)}

            />
            
            <Switch>
              <Redirect from="/" exact to="/auth" />
              <Route path="/auth" component={AuthPage} />
              {/* <Route path="/home" component={HomePage} /> */}
              <Redirect from="/edit" exact to="/edit/profile" />
              <PrivateRoute
                path="/edit/:tab"
                render={(props) => <EditPage mainRoute="edit" {...props} />}
              />
              <Redirect from="/operations" exact to="/operations/contracting" />
              <PrivateRoute
                path="/operations/:tab"
                render={(props) => (
                  <OperationsPage mainRoute="operations" {...props} />
                )}
              />
              <Redirect from="/old-operations" exact to="/old-operations/contracting" />
              <PrivateRoute
                path="/old-operations/:tab"
                render={(props) => (
                  <OldOperationsPage mainRoute="old-operations" {...props} />
                )}
              />
              <PrivateRoute path="/originator" component={OriginatorPage} permission="admin" />
              <PrivateRoute path="/customers" component={CustomersPage} />
              <PrivateRoute path="*" component={NotFoundPage} />
            </Switch>
          </Grid>
        </Container>
        <Cookies />
      </Box>
      {!url.includes("/auth") && <Footer drawerWidth={"200px"} />}
    </Box>
  );
});
