import React from "react";
import { Snackbar, SnackbarContent, IconButton, Button } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Box, Progress, Typography, Icon } from "..";
import { useStyles } from "./style";

export const AlertComponent = (props) => {
  const classes = useStyles({ width: props.width });

  switch (props.component) {
    case "temporary":
      return (
        <Snackbar
          open={props.open}
          autoHideDuration={props.duration || 6000}
          onClose={props.handleCloseSnackbar}
          {...props}
        >
          <Alert onClose={props.handleCloseSnackbar} severity={props.severity}>
            {props.children || props.message}
          </Alert>
        </Snackbar>
      );
    case "loading":
      return (
        <Snackbar
          open={props.open}
          onClose={props.handleCloseSnackbar}
          {...props}
        >
          <SnackbarContent
            className={classes.snackbarContent}
            message={
              <Box display="flex" justifyContent="space-between">
                <Progress size="20px" name="circular" />
                <Typography>Carregando...</Typography>
              </Box>
            }
          />
        </Snackbar>
      );
      case "action":
        return(
          <Snackbar
          anchorOrigin={ props.anchorOrigin || {
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={props.open}
          autoHideDuration={props.duration || 6000}
          onClose={props.onClose}
          message={props.message || "Ação realizada!"}
          action={
            <React.Fragment>
              <Button color="secondary" size="small" onClick={props.onClick}>
                Desfazer
              </Button>
              <IconButton size="small" aria-label="close" color="inherit" onClick={props.onClose}>
                <Icon name="close" fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
          {...props}
        />
        );
      case "simple":
        return(
          <Snackbar
          anchorOrigin={ props.anchorOrigin || {
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={props.open}
          autoHideDuration={props.duration || 6000}
          onClose={props.onClose}
          message={props.message || "Ação realizada!"}
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={props.onClose}>
                <Icon name="close" fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
          {...props}
        />
        );
    default:
      return (
        <Alert
          className={classes.alert}
          severity={props.severity || "info"}
          variant={props.variant || "standard"}
          {...props}
        >
          {props.children || props.message}
        </Alert>
      );
  }
};
