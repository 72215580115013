import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme, props) => ({
  offset: theme.mixins.toolbar,
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    maxWidth: "100px",
  },
  appBar: {
    backgroundColor: theme.palette.primary.dark,
    [theme.breakpoints.up("sm")]: {
      width: (prop) => `calc(100% - ${prop.drawerWidth})`,
      marginLeft: (prop) => prop.drawerWidth,
    },
  },
  toolBar: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  }
}));
