import { Box, Typography } from "../../../../../components";

export const BankInformationComponent = (props) => {
  const { bank_name, agency, account } = props.dataBank;

  return (
    <Box display="flex" flexDirection="column" my={2}>
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Typography variant="body1" fontWeight={700}>
          Banco:
        </Typography>
        <Typography>{bank_name ? bank_name : "-"}</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Typography variant="body1" fontWeight={700}>
          Agência:
        </Typography>
        <Typography>{agency ? agency : "-"}</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Typography variant="body1" fontWeight={700}>
          Conta Corrente:
        </Typography>
        <Typography>{account ? account : "-"}</Typography>
      </Box>
    </Box>
  );
};
