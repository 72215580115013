import React from "react";
import { Controls } from "../../../../components/controls";
import { Box } from "../../../../components";
import { bankOptions } from "../../../../utils";
import { useStyles } from "../../style";

export const BankStepComponent = (props) => {
  const classes = useStyles();
  return (
    <>
      <Controls.Input
        name="ec"
        control={props.control}
        label="EC"
        placeholder="Digite o EC do cliente"
        fullWidth
        required
      />
      <Controls.Select
        name="bank"
        label="Banco"
        control={props.control}
        placeholder="Digite o banco do cliente"
        size="small"
        options={bankOptions}
        required
        fullWidth
        className={classes.select}
      />
      <Box display="flex" justifyContent="space-between">
        <Controls.Input
          name="bankBranch"
          control={props.control}
          label="Agência"
          type="text"
          placeholder="Digite a agência do cliente"
          fullWidth
          required
          className={classes.input}
        />
        <Controls.Input
          name="bankAccount"
          control={props.control}
          label="Conta"
          placeholder="Digite a conta do cliente"
          required
          type="text"
          className={classes.input}
        
        />
        <Controls.Input
          name="accountDigit"
          control={props.control}
          label="Dígito"
          placeholder="Digite a dígito do cliente"
          required
          className={classes.input}
        />
      </Box>
    </>
  );
};
