import React from "react";
import { Helmet } from 'react-helmet';
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { useIsMobile } from "../../hooks/useIsMobile";
import { Box, Icon, Typography } from "../../components";

import { useStyles } from "./style";

export const NotFoundPageComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const isMobile = useIsMobile();

  const handleRedirectHome = () => {
    history.push("/customers");
  };

  return (
    <Box
      justifyContent="center"
      alignItems="center"
      display="flex"
      width="100%"
      height="100vh"
      flexDirection={isMobile ? "column" : "row"}
    >
      <Helmet>
        <title>Página não encontrada | Originador </title>
      </Helmet>
      <Icon name="sad" className={classes.iconSad} />
      <Box
        maxWidth="500px"
        display={isMobile ? "flex" : "block"}
        flexDirection={isMobile ? "column" : "row"}
      >
        <Box>
          <Typography
            variant="h4"
            component="h1"
            fontWeight={700}
            align={isMobile ? "center" : "left"}
          >
            404 - Página não encontrada
          </Typography>
        </Box>
        <Box display="flex" my={2} align={isMobile ? "center" : "left"}>
          <Typography variant="h6" component="h1">
            Essa página que você está procurando pode ter sido removida ou está
            temporariamente indisponível.
          </Typography>
        </Box>
        <Button color="primary" variant="contained" onClick={handleRedirectHome}>
          Voltar para página inícial
        </Button>
      </Box>
    </Box>
  );
};
