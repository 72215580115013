import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { CustomersDetailsPage } from "./Details";
import { CustomersMainPage } from "./Main";

export const CustomersPageComponent = (props) => {
  return (
    <Switch>
      <Redirect from="/customers/details" exact to="/customers" />
      <Route
        exact
        path="/customers"
        render={(p) => <CustomersMainPage {...p}  {...props}/>}
      />
       <Route
        path="/customers/details/:id"
        render={(props) => <CustomersDetailsPage {...props} mainRoute="customers" />}
      />
    </Switch>
  );
};
