import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
  return {
    iconSad: {
      fontSize: '200px',
      color: theme.palette.primary.main
    }
  };
});
