import React from "react";
import { Box, Paper, Typography } from "../../components";
import { Button, Avatar } from "@material-ui/core";
import { useStyles } from "./style";
import { useIsMobile } from "../../hooks/useIsMobile";
import { captalizeString } from "../../utils";
import { formatToCNPJ, formatToCPF, formatToPhone } from "brazilian-values";

export const ResumeOriginatorComponent = (props) => {
  const classes = useStyles({ status: props.data.status });
  const isMobile = useIsMobile();

  function getNameStatus(status) {
    switch (status) {
      case "approved":
        return "Aprovado";
      case "declined":
        return "Reprovado";
      default:
        return "Pendente";
    }
  }

  const handleAction = (value) => props.onHandleAction(value);

  return (
    <Paper
      display="flex"
      justifyContent="space-between"
      my={1}
      flexDirection={isMobile ? "column" : "row"}
      scroll="none"
    >
      <Box
        display="flex"
        flexDirection="column"
        width={isMobile ? "100%" : "20%"}
        my={2}
      >
        <Avatar className={classes.avatar}>
          {props.data.status.charAt(0).toUpperCase() === "D"? "R" : props.data.status.charAt(0).toUpperCase()  }
        </Avatar>
        <Typography component="p">{getNameStatus(props.data.status)}</Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        flexDirection={isMobile ? "column" : "row"}
        mx={isMobile ? 0 : 4}
        my={2}
      >
        <Box
          flexDirection="column"
          alignItems="flex-start"
          width={isMobile ? "100%" : "45%"}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography component="p" fontWeight="bold">
              Nome:
            </Typography>
            <Typography component="p"> {captalizeString(props.data.name)}</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography component="p" fontWeight="bold" className={classes.textField}>
              E-mail:
            </Typography>
            <Typography component="p" ellipsis="true">
              {props.data.email}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography component="p" fontWeight="bold">
              CPF:
            </Typography>
            <Typography component="p" className={classes.textField}>
              {props.data.cpf === "-" ? props.data.cpf : formatToCPF(props.data.cpf)}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography component="p" fontWeight="bold">
              Celular:
            </Typography>
            <Typography component="p">
              {props.data.cellphone === "-"
                ? props.data.cellphone
                : formatToPhone(props.data.cellphone)}
            </Typography>
          </Box>
        </Box>
        <Box
          flexDirection="column"
          alignItems="flex-start"
          width={isMobile ? "100%" : "45%"}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography component="p" fontWeight="bold">
              Razão Social:
            </Typography>
            <Typography component="p">{props.data.corporate_name}</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography component="p" fontWeight="bold">
              CNPJ:
            </Typography>
            <Typography component="p">
              {props.data.cnpj === "-" ? props.data.cnpj : formatToCNPJ(props.data.cnpj)}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent={isMobile ? "center" : "flex-end"}
            alignItems=""
            my={2}
          >
            <Button 
              variant="contained" 
              className={classes.buttonReproval} 
              disabled={props.disabledButton.reproval}
              onClick={ () => handleAction({id: props.data.user_id, prev_status: props.data.status, next_status:'declined'}) }
            >
              Reprovar
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonApproval}
              disabled={props.disabledButton.approval}
              onClick={ () => handleAction({id: props.data.user_id, prev_status: props.data.status, next_status:'approved'}) }
            >
              Aprovar
            </Button>
          </Box>
        </Box>
      </Box>
     
    </Paper>
  );
};
