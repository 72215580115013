import React from "react";
import Alert from "@material-ui/lab/Alert";
import { Tabs } from "../../components";
import { ContractingPage } from "./contracting";
import { PortfolioPage } from "./porfolio";
import { Box } from "../../components/Box";
import { useStyles } from "./style";

export const OldOperationsPageComponent = (props) => {
  const classes = useStyles()

  return (
    <Box display="flex" flexDirection="column" gap="2">
      <Alert severity="info" width="100%" className={classes.root}>Estamos em processo de migração, aqui você poderá visualizar suas operações antigas</Alert>

      <Tabs
        tabs={[
          {
            title: "Em contratação",
            content: <ContractingPage />,
            routerName: "contracting",
          },
          {
            title: "Operações em carteira",
            content: <PortfolioPage />,
            routerName: "portfolio",
          },
        ]}
        {...props}
      />
    </Box>
  );
};
