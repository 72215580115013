import React from "react";
import { useHistory } from "react-router-dom";
import Helmet from "react-helmet";
import { Button, Link as MaterialLink } from "@material-ui/core";
import { Box, Icon, Paper, Typography } from "../../../components";
import { logout } from "../../../services/auth"

import { useStyles } from "./style";

export const WaitingApprovalComponent = (props) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Box display="flex" justifyContent="space-evenly" mt={8}>
      <Helmet>
        <title>Aguardando aprovação | Originador </title>
      </Helmet>
      <Paper maxWidth="500px" p={4} elevation={1}>
        <Box display="flex" flexDirection="column">
          <Box display="flex" flexDirection="column">
            <Icon name="clock" style={{ fontSize: 120 }} />
          </Box>
          <Box display="flex" justifyContent="flex-start" mt={2}>
            <Typography variant="h5" fontWeight="bold" className={classes.title}>
              Obrigado por se cadastrar!
            </Typography>
          </Box>
          <Box my={2}>
            <Typography variant="h6" className={classes.title}>
              Por favor, aguarde enquanto a Captalys avalia seu pedido e
              libera seu acesso.
            </Typography>
          </Box>
          <Box>
            <Typography>
              Fique tranquilo! A aprovação do seu acesso não leva mais que
              12 horas, mas se até lá você precisar esclarecer alguma dúvida,
              pode falar com o nosso time.
            </Typography>
            
          </Box>
          <Box display="flex" flexDirection="column" alignItems="flex-start" mt={3}>
            <Typography>
              Fale com o seu gestor de contas ou entre em contato:
            </Typography>
            <Box display="flex" flexDirection="column" alignItems="flex-start" mt={1}>
              <MaterialLink href="https://wa.me/5511957842276" target="_blank" rel="noopener noreferrer">
                <Typography gutterBottom>
                  (11) 95784-2276
                </Typography>
              </MaterialLink>
              <MaterialLink href="mailto:contato@tomatico.com.br">
                <Typography gutterBottom>
                  contato@tomatico.com.br
                </Typography>
              </MaterialLink>
              <MaterialLink href="tel:1123917844">
                <Typography gutterBottom>
                  (11) 2391-7844
                </Typography>
              </MaterialLink>
            </Box>
            <Box display="flex" mt={2}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => logout(history)}
                fullWidth
              >
                Voltar
              </Button>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};
