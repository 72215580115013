import React from "react";
import { Controls } from "../../../../components/controls";
import { formOptions } from "./props";
import { useForm } from "react-hook-form";
import { Box, Typography, Alert } from "../../../../components";
import { Button } from "@material-ui/core";
import { api } from "../../../../services/api";
import { useStyles } from "./style";
import { bankOptions } from "../../../../utils";
import { handlingErrors, handlingSuccess } from "../../../../utils";


export const EditMenuComponent = (props) => {
  const classes = useStyles();
  const { handleSubmit, control, reset } = useForm(formOptions);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isAlert, setIsAlert] = React.useState({
    open: false,
    message: "",
    status: "",
  });


  const routeAdditionalInformation = `/client/${props.cnpj}/additional-information`;

  React.useEffect(() => {
    api.get(routeAdditionalInformation)
      .then((response) => {
        const { client, company, bank } = response?.data;
        reset({
          bankAccountId: bank.id,
          cnpj: company?.cnpj,
          email: client.email,
          cellphone: client.telephone,
          bank: bank.bank_compe?.trim(),
          bankBranch: bank.bank_branch_number,
          bankAccount: bank.account_number,
          accountDigit: bank.account_digit,
        })
        setIsLoading(false);
      }).catch(error => {
        handlingErrors(error, setIsAlert);
        setIsLoading(false);

      })

  }, [routeAdditionalInformation, reset])

  const onSubmit = async (data) => {
    setIsLoading(true);

    console.log({ data })

    api
      .patch(`/client/${props.ecid}/update`, {
        produto: sessionStorage.getItem('product'),
        cnpj: data.cnpj,
        email: data.email,
        celular: data.cellphone,
        bank: data.bank,
        bankAccountId: data.bankAccountId || undefined,
        bankBranch: data.bankBranch.toString(),
        bankAccount: data.bankAccount.toString(),
        accountDigit: data.accountDigit
      })
      .then((response) => {
        handlingSuccess(response, setIsAlert);
        props.handleCloseDialog();
      })
      .catch((error) => {
        handlingErrors(error, setIsAlert);
      });
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box mt={1}>
        <Typography fontWeight="bold" variant="h5">
          Editar Dados
        </Typography>
      </Box>
      <Box my={2} >
        <form noValidate>
          <Controls.Input
            name="email"
            control={control}
            label="Email"
            placeholder="Digite o seu email"
            fullWidth
            required
            disabled={isLoading}
          />
          <Controls.InputMask
            name="cellphone"
            control={control}
            label="Celular"
            placeholder="Digite o seu celular"
            fullWidth
            required
            disabled={isLoading}
            mask="(99) 99999-9999"
          />
          <Controls.Select
            name="bank"
            label="Banco"
            control={control}
            placeholder="Digite o banco do cliente"
            size="small"
            options={bankOptions}
            required
            disabled={isLoading}
            fullWidth
            className={classes.select}
          />
          <Box display="flex" justifyContent="space-between">
            <Controls.Input
              name="bankBranch"
              control={control}
              label="Agência"
              type="text"
              placeholder="Digite a agência do cliente"
              fullWidth
              required
              disabled={isLoading}
              className={classes.input}
            />
            <Controls.Input
              name="bankAccount"
              control={control}
              label="Conta"
              placeholder="Digite a conta do cliente"
              required
              disabled={isLoading}
              type="text"
              className={classes.input}
            />
            <Controls.Input
              name="accountDigit"
              control={control}
              label="Dígito"
              placeholder="Digite a dígito do cliente"
              required
              disabled={isLoading}
              className={classes.input}
            />
          </Box>
        </form>
      </Box>
      <Box display="flex" justifyContent="space-between" mb={1}>
        <Button variant="contained" onClick={props.handleCloseDialog}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading}
        >
          Enviar
        </Button>
      </Box>
      {isLoading && <Alert component="loading" open={isLoading} />}
      {isAlert.open && (
        <Alert
          component="temporary"
          open={isAlert.open}
          severity={isAlert.status}
          message={isAlert.message}
          onClose={(state) => setIsAlert({ ...state, open: false })}
        />
      )}
    </Box>
  );
};
