export const getToken = () => sessionStorage.getItem("token")

export const isAuthenticated = () => getToken() !== null;

export const logout = (history) => {

  sessionStorage.clear();

  history ?? (window.location.href = "/");
  
  history.push('/auth')
};
