import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  MobileStepper,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Box, Icon, Typography } from "../../../components";
import { RegistrationInformation, BankInformation, BusinessInformation } from "./Steps";

const nameCurrentSteps = [
  { label: "Informações Cadastrais" },
  { label: "Informações Empresariais" },
  { label: "Informações Bancárias" },
];

export const DialogAdditionalInformationComponent = (props) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = nameCurrentSteps.length;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCloseDialog = () => props.onClose();

  const currentStep = (name) => {
    const content = {
      'Informações Cadastrais': <RegistrationInformation dataRegistration={props.client} />,
      'Informações Empresariais': <BusinessInformation dataBusiness={props.company} />,
      'Informações Bancárias': <BankInformation dataBank={props.bank}/>,
    }

    return content[name] || 'Etapa desconhecida';
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="sm"
      scroll="body"
      fullWidth
      fullScreen={fullScreen}
    >
      <DialogContent>
        <Box p={2}>
          <Box display="flex" flexDirection="column" my={2}>
            <Typography fontWeight="bold">
              {nameCurrentSteps[activeStep].label}
            </Typography>
            {currentStep(nameCurrentSteps[activeStep].label)}
          </Box>
          <MobileStepper
            steps={maxSteps}
            position="static"
            variant="dots"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
              >
                Próximo
                {theme.direction === "rtl" ? (
                  <Icon name="arrowLeft" />
                ) : (
                  <Icon name="arrowRight" />
                )}
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                {theme.direction === "rtl" ? (
                  <Icon name="arrowRight" />
                ) : (
                  <Icon name="arrowLeft" />
                )}
                Voltar
              </Button>
            }
          />
           <Box display="flex">
            <Button
              variant="contained"
              color="primary"
              onClick={handleCloseDialog}
            >
              Fechar
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
