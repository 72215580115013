import { PieChart as Pie } from './PieChart';
import { LineChart as Line } from './LineChart';
import { BarStackedChart as BarStacked } from './BarStackedChart';
import { BarChart as Bar } from './BarChart';

export const Chart = {
  Pie,
  Line,
  Bar,
  BarStacked
};

