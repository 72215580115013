import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
  return {
    input: {
      width: '32%',
    },
    select: {
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  };
});
