import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#32AFDC',
      light: '#007CA9',
      dark: '#003750',
      contrastText: '#fff',
    },
    secondary: {
      main: '#2CB567',
      light: '#56C385',
      dark: '#1E7E48',
      contrastText: '#fff',
    },
    error: {
      main: '#f44336',
      light: '#e57373',
      dark: '#d32f2f ',
      contrastText: '#fff',
    },
    warning: {
      main: '#ff9800',
      light: '#ffb74d',
      dark: '#f57c00',
      contrastText: '#fff',
    },
    success: {
      main: '#4caf50',
      light: '#81c784',
      dark: '#388e3c',
    },
    status: {
      main: '#A4AFB7',
      success: '#2CB567',
      warning: '#ff9800',
    },
    base: {
      main: '#ccc',
      light: '#fff',
      dark: '#000',
    },
    otherColors: {
      lightGray: '#D9D9D9',
      lightGreen: '#CDF4D5',
      darkBlueNames: '#143D66',
      lightBlue: '#DCF2F9',
    },
  },

  typography: {
    fontFamily: 'Mulish',
    button: {
      textTransform: 'none',
      fontWeight: 600
    },
  },



  overrides: {
    MUIDataTableToolbar: {
      root: {
        color: "#343741",
        minHeight: '55px',
      }
    },

    MuiTableCell: {
      root: {
        padding: '8px',
        borderBottom: '1px solid #EBEBEB',
      },
      head: {
        fontWeight: 'bold',
        backgroundColor: '#EBEBEB !important',
        '&:nth-child(7)': {
          width: '120px',
        }
      },
    },

    MuiTableRow: {
      root: {
        height: "55px",
      },
    },

    MUIDataTableBodyRow: {
      root: {
        '& > *': {
          '&:nth-child(2)': {
            '& > div > p > span ': {
              maxWidth: '200px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              '-webkit-line-clamp': 2,
              '-webkit-box-orient': 'vertical',
              display: '-webkit-box',
            },
          },
        },
        '&:nth-child(2n)': {
          backgroundColor: '#F5FAFE',
        },
      },
    },
  }
});
