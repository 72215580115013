import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export const validationSchema = Yup.object().shape({
  zipCode: Yup.string().required("O campo cep é obrigatório."),
  address: Yup.string().required("O campo endereço é obrigatório."),
  number: Yup.string().required("O campo número é obrigatório."),
  district: Yup.string().required("O campo bairro é obrigatório."),
  city: Yup.string().required("O campo cidade é obrigatório."),
  state: Yup.string().required("O campo estado é obrigatório."),
});

export const formOptions = {
  resolver: yupResolver(validationSchema),
  defaultValues: {
    zipCode: "",
    address: "",
    number: "",
    district: "",
    city: "",
    state: "",
    complement: "",
  },
};
