import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) =>({
  alert: {
    width: props => props.width ? props.width : "100%"
  },
  snackbarContent:{
    "& > div":{
      width:"50%"
    }
  }
}))
