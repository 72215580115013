import React from "react";

export const GlobalContext = React.createContext();

export const GlobalStorage = (props) => {
  const [toggleAddCustomer, setToggleAddCustomer] = React.useState(false);
  const [openDialogAddCustomer, setOpenDialogAddCustomer] = React.useState(false);

  return (
    <GlobalContext.Provider value={{ 
      toggleAddCustomer, 
      setToggleAddCustomer, 
      openDialogAddCustomer, 
      setOpenDialogAddCustomer,
    }}>
      {props.children}
    </GlobalContext.Provider>
  );
};
