import React from "react";
import { Link } from "react-router-dom";
import { AppBar, Container, Toolbar } from "@material-ui/core";
import { Link as MaterialLink } from "@material-ui/core";
import { Box, Typography } from "../../components";
import logo from "../../assets/icons/logo_go.svg";
import logoCaptalys from "../../assets/images/logo-captalys.svg";
import { useIsMobile } from "../../hooks/useIsMobile";

import { useStyles } from "./style";

export const FooterComponent = (props) => {
  const urlCaptalys = "https://www.captalys.com.br";
  const classes = useStyles({ drawerWidth: props.drawerWidth });
  const isMobile = useIsMobile();

  return (
    <AppBar position="static" className={classes.footer}>
      <Container maxWidth="lg">
        <Toolbar>
          <Box
            display="flex"
            justifyContent="space-between"
            my={2}
            flexDirection={isMobile ? "column" : "row"}
          >
            <Box
              display="flex"
              justifyContent={isMobile ? "center" : "flex-start"}
              width={isMobile ? "100%" : "15%"}
              alignSelf="flex-start"
            >
              <MaterialLink component={Link} to="/customers">
                <img
                  width="100px"
                  height="100px"
                  src={logo}
                  alt="Logo Grupos de Originadores"
                />
              </MaterialLink>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems={isMobile ? "center" : "flex-start"}
              width={isMobile ? "100%" : "65%"}
              my={isMobile ? 2 : 0}
            >
              <MaterialLink
                component={Link}
                className={classes.link}
                to="/customers"
              >
                Clientes
              </MaterialLink>
              <MaterialLink
                component={Link}
                className={classes.link}
                to="/operations"
              >
                Operações
              </MaterialLink>
              <MaterialLink
                component={Link}
                className={classes.link}
                to="edit/profile"
              >
                Meus dados
              </MaterialLink>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems={isMobile ? "center" : "flex-start"}
              alignSelf="flex-start"
              width={isMobile ? "100%" : "20%"}
            >
              <Typography>Acesse nosso site:</Typography>
              <Box mt={2}>
                <MaterialLink
                  target="_blank"
                  href={urlCaptalys}
                  rel="noopener noreferrer"
                >
                  <img
                    width="100px"
                    height="50px"
                    src={logoCaptalys}
                    alt="Logo Captalys"
                  />
                </MaterialLink>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
