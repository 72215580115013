import React from "react";
import { useParams } from "react-router";
import { formatToBRL } from "brazilian-values";
import { api } from "../../../../services/api";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import { Alert, Box, Divider, Icon, Typography } from "../../../../components";
import { handlingErrors } from "../../../../utils";

export const ResumeTemplate = (props) => {
  const [data, setData] = React.useState([]);
  const [isAlert, setIsAlert] = React.useState({
    open: false,
    message: "",
    status: "",
  });
  const isMobile = useIsMobile();
  const { id } = useParams();
  const routeClientSummary = `/client/${id}/statement/summary`;

  const handleCloseSnackbar = () =>
    setIsAlert((prevAlert) => ({ ...prevAlert, open: false }));

  React.useEffect(() => {
    api
      .get(routeClientSummary)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        handlingErrors(error, setIsAlert);
      });
  }, [routeClientSummary]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      my={isMobile ? 2 : 0}
      alignItems="initial"
      mt={3}
    >
      {isAlert.open && (
        <Alert
          component="temporary"
          open={isAlert.open}
          severity={isAlert.status}
          message={isAlert.message}
          onClose={handleCloseSnackbar}
        />
      )}
      <Box display="flex" alignItems="initial">
        <Icon name="document" size="80px" viewBox="0 05 85 70" />
        <Box display="flex" flexDirection="column">
          <Typography variant="h5" fontWeight={700}>
            {formatToBRL(data.totalPaymentsAmount || 0)} <br />
            <Typography variant="body1" color="textSecondary">
              Valor total das vendas
            </Typography>
          </Typography>
          <Box mt={4}>
            <Typography variant="h5" fontWeight={700}>
              {formatToBRL(data.totalNetValue || 0)} <br />
              <Typography variant="body1" color="textSecondary">
                Pagamento realizado
              </Typography>
              <Typography variant="body1" align="left" color="textSecondary">
                12% das vendas
              </Typography>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box my={3}>
        <Divider />
      </Box>
      <Box display="flex">
        <Typography variant="h4" fontWeight={700} color="primary">
          {formatToBRL(data.totalSalesAmount || 0)} <br />
          <Typography variant="body1" color="textSecondary">
            Valor liberado em conta
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
};
