import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  topbar: {
    zIndex: 1000,
    backgroundColor: `${theme.palette.base.light}`,
    top: 64,
    [theme.breakpoints.down("md")]: {
      top: 56,
    },
    maxHeight: 64,
    justifyContent: "center",
    alignItems: "flex-end",
    [theme.breakpoints.up("sm")]: {
      width: (prop) => `calc(100% - ${prop.drawerWidth})`,
      marginLeft: (prop) => prop.drawerWidth,
    },
  },
  typography: {
    color: theme.palette.otherColors.darkBlueNames,
    fontWeight: 'bold',
  },
}));
