import React from "react";
import { IconButton } from "@material-ui/core";
import { Icon } from "../../components";
import { useStyles } from "./style";

export const ScrollButtonComponent = () => {
  const classes = useStyles();

  const [visible, setVisible] = React.useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 150) {
      setVisible(true);
    } else if (scrolled <= 150) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <>
      {visible ? (
        <IconButton
          variant="contained"
          className={classes.button}
          onClick={scrollToTop}
        >
          <Icon name="arrowUp" className={classes.icon} />
        </IconButton>
      ) : (
        ""
      )}
    </>
  );
};
