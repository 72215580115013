import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
 sticky: {
  position:"sticky", 
  top:"140px",
  zIndex: 1100,
  animation: "500ms ease-in-out 0s normal none 1 running fadeInDown",
  border: `2px solid ${theme.palette.primary.main}`
 }
}));
