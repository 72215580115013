import React from "react";
import {
  Dialog,
  DialogContent,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Box } from "../../../../components";
import { DeleteMenu } from "../Delete";
import { EditMenu } from "../Edit";
import { ResetMenu } from "../ResetOperation";

export const DialogMenuComponent = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const handleCloseDialog = () => props.onClose();

  const optionsMenu = (value) => {
    const object = {
      Editar: <EditMenu handleCloseDialog={handleCloseDialog} ecid={props.ecid} cnpj={props.cnpj}/>,
      Remover: <DeleteMenu handleCloseDialog={handleCloseDialog} />,
      Resetar: <ResetMenu handleCloseDialog={handleCloseDialog} />,
    };

    return object[value] || value;
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="xs"
      scroll="body"
      fullWidth
      fullScreen={fullScreen}
    >
      <DialogContent>
        <Box display="flex" flexDirection="column">
          {optionsMenu(props.option)}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
