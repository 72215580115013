import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
 ellipsis: {
  width: "250px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textAlign: "right"
 }
}));
