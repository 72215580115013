import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export const validationSchema = [
  Yup.object({
    cnpjCpf: Yup.string().required(
      "O campo CNPJ ou CPF completo é obrigatório."
    ),
    fullName: Yup.string().required("O campo nome completo é obrigatório."),
    username: Yup.string().required("O campo username é obrigatório."),
    email: Yup.string()
      .required("O campo e-mail é obrigatório.")
      .email("Por favor digite um e-mail válido."),
    cellphone: Yup.string()
      .required("O campo celular é obrigatório.")
      .test("lenght", "Por favor digite um telefone válido.", (val) => {
        const valLengthWithoutDashes = val.replace(/-|_/g, "").length;
        return valLengthWithoutDashes === 14;
      }),
    password: Yup.string()
      .required("O campo senha é obrigatório")
      .min(8, "Por favor digite uma senha com no mínimo 8 caracteres."),
    confirmPassword: Yup.string()
      .required("O campo confirmar senha é obrigatório")
      .oneOf([Yup.ref("password"), null], "As senhas não são iguais."),
    acceptTerms: Yup.bool()
      .required("O campo termos e condições precisar ser aceito.")
      .oneOf([true], "O campo termos e condições precisar ser aceito."),
  }),
  Yup.object({
    zipCode: Yup.string().required("O campo cep é obrigatório."),
    address: Yup.string().required("O campo endereço é obrigatório."),
    number: Yup.string().required("O campo número é obrigatório."),
    district: Yup.string().required("O campo bairro é obrigatório."),
    city: Yup.string().required("O campo cidade é obrigatório."),
    state: Yup.string().required("O campo estado é obrigatório."),
  }),
];

export const formOptions = (activeStep) => {
  return {
    shouldUnregister: false,
    resolver: yupResolver(validationSchema[activeStep]),
    defaultValues: {
      cnpjCpf: "",
      fullName: "",
      username: "",
      email: "",
      cellphone: "",
      password: "",
      confirmPassword: "",
      acceptTerms: false,
      zipCode: "",
      address: "",
      number: "",
      district: "",
      city: "",
      state: "",
      complement: "",
    },
    mode: "onChange",
  };
};

export const stateOptions = [
  { name: "São Paulo", value: "SP" },
  { name: "Rio de Janeiro", value: "RJ" },
  { name: "Acre", value: "AC" },
  { name: "Alagoas", value: "AL" },
  { name: "Amapá", value: "AP" },
  { name: "Amazonas", value: "AM" },
  { name: "Bahia", value: "BA" },
  { name: "Ceará", value: "CE" },
  { name: "Distrito Federal", value: "DF" },
  { name: "Espírito Santo", value: "ES" },
  { name: "Goiás", value: "GO" },
  { name: "Maranhão", value: "MA" },
  { name: "Mato Grosso", value: "MT" },
  { name: "Mato Grosso do Sul", value: "MS" },
  { name: "Minas Gerais", value: "MG" },
  { name: "Pará", value: "PA" },
  { name: "Paraíba", value: "PB" },
  { name: "Paraná", value: "PR" },
  { name: "Pernambuco", value: "PE" },
  { name: "Piauí", value: "PI" },
  { name: "Rio Grande do Norte", value: "RN" },
  { name: "Rio Grande do Sul", value: "RS" },
  { name: "Rondônia", value: "RO" },
  { name: "Roraima", value: "RR" },
  { name: "Santa Catarina", value: "SC" },
  { name: "Sergipe", value: "SE" },
  { name: "Tocantins", value: "TO" },
  { name: "Estrageiro", value: "Estrangeiro" },
];
