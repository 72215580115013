import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
  return {
    actions: {
      padding: theme.spacing(1, 3, 3, 3),
    },
    title: {
      color: theme.palette.primary.main,
    },
  };
});
