import React from "react";
import { Divider } from "@material-ui/core";
import { useStyles } from "./style";

export const DividerComponent = (props) => {
  const { color } = props;
  const classes = useStyles({ color: color });
  
  return <Divider className={classes.divider} {...props} />;
};
