import React from "react";
import { Helmet } from "react-helmet";
import { Button } from "@material-ui/core";
import {
  Alert,
  Box,
  Paper,
  Table,
  Typography,
  Icon,
  Loading,
} from "../../../components";
import { downloadExcel, donwloadPdf } from "../utils";
import { tableContractingColumns } from "./props";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { api, routeOperationsContracting } from "../../../services/api";
import { handlingErrors, handlingSuccess } from "../../../utils";
import { formatToBRL } from "brazilian-values";
import { Empty } from "../../../layout";
import { debounceSearchRender } from "mui-datatables";

export const ContractingPageComponent = (props) => {
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [count, setCount] = React.useState(1);
  const [resume, setResume] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isLoadingAlert, setIsLoadingAlert] = React.useState(false);
  const isMobile = useIsMobile();
  const [sortOrder, setSortOrder] = React.useState({
    name: "id",
    direction: "desc",
  });
  const [isAlert, setIsAlert] = React.useState({
    open: false,
    message: "",
    status: "",
  });
  const accessType = sessionStorage.getItem("access_type");

  const handleCloseSnackbar = () =>
    setIsAlert((prevAlert) => ({ ...prevAlert, open: false }));

  const tableContractingOptions = {
    download: false,
    print: false,
    filter: false,
    rowHover: false,
    selectableRows: "none",
    rowsPerPageOptions: [10, 25, 50],
    responsive: "vertical",
    tableBodyMaxHeight: "",
    serverSide: true,
    count: count,
    page: page,
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          setIsLoadingAlert(true);
          getData(tableState.page);
          setPage(tableState.page);
          break;
        case "changeRowsPerPage":
          setIsLoadingAlert(true);
          getData(tableState.page, tableState.rowsPerPage);
          setPage(tableState.page);
          break;
        case "search":
          setIsLoadingAlert(true);
          getData(
            tableState.page,
            tableState.rowsPerPage,
            tableState.searchText
          );
          setPage(tableState.page);
          break;
        case "sort":
          setIsLoadingAlert(true);
          getData(
            tableState.page,
            tableState.rowsPerPage,
            tableState.searchText,
            tableState.sortOrder.name,
            tableState.sortOrder.direction
          );
          setPage(tableState.page);
          break;
        default:
          return 0;
      }
    },
    customSearchRender: debounceSearchRender(1000),
    onColumnSortChange: (changedColumn, direction) => {
      let order = "desc";
      if (direction === "asc") {
        order = "asc";
      }
      setSortOrder((prevState) => ({
        ...prevState,
        name: changedColumn,
        direction: order,
      }));
    },
    sortOrder: sortOrder,
    textLabels: {
      body: {
        noMatch: "Desculpe, não encontramos nada",
        toolTip: "Ordenar",
      },
      selectedRows: {
        text: "linha(s) selecionada",
      },
      pagination: {
        next: "Próxima página",
        previous: "Página anterior",
        rowsPerPage: "Linhas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Procurar",
        viewColumns: "Visualizar colunas",
        filterTable: "Filtrar tabela",
      },
      viewColumns: {
        title: "Mostrar colunas",
        titleAria: "Mostrar/Esconder colunas da tabela",
      },
    },
  };

  React.useEffect(() => {
    getData();
  }, []);

  const getData = (
    page = 0,
    limit = 10,
    search = null,
    columnSort = "id",
    columnDirection = "desc"
  ) => {
    api
      .get(
        `${routeOperationsContracting}?page=${
          page + 1
        }&limit=${limit}&search=${search}&sort=${columnSort}&direction=${columnDirection}`
      )
      .then((response) => {
        setCount(response.data.total);

        if (response.data.operations.length === 0) {
          return handlingSuccess(response, setIsAlert);
        }

        const responseMap = response.data.operations.map((item) => {
          const ecid = item.ecid ? item.ecid : "";
          const cnpj = item.cnpj;
          const viewDetails = { cnpj: cnpj, ecid: ecid };
          const progress = item.progressao;
          return (item = { ...item, ...progress, viewDetails });
        });
        const extras = response.data.extras;
        setResume(extras);
        setData(responseMap);
      })
      .catch((error) => {
        handlingErrors(error, setIsAlert);
      })
      .finally(() => {
        setIsLoading(false);
        setIsLoadingAlert(false);
      });
  };

  return isLoading ? (
    <Box display="flex" height="100vh">
      <Loading />
    </Box>
  ) : (
    <Box display="block">
      <Helmet>
        <title>Operações | Originador </title>
      </Helmet>
      {data.length === 0 ? (
        <Empty>{isAlert.message}</Empty>
      ) : (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            mb={3}
            flexDirection={isMobile ? "column" : "row"}
          >
            <Paper
              display="flex"
              width={isMobile ? "100%" : "70%"}
              flexDirection={isMobile ? "column" : "row"}
              justifyContent="space-between"
              my={2}
              minHeight="150px"
              elevation={3}
              p={2}
              scroll="auto"
            >
              <Box
                display="flex"
                flexDirection={isMobile ? "column" : "row"}
                flexWrap={isMobile ? "wrap" : "nowrap"}
                minWidth={isMobile ? "100%" : "650px"}
                justifyContent="space-between"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection={isMobile ? "column" : "row"}
                  my={isMobile ? 2 : 0}
                >
                  <Icon name="document" size="60px" viewBox="0 05 85 70" />
                  <Typography variant="h5" fontWeight={700}>
                    {resume.operations_quantity || "0"} <br />
                    <Typography
                      variant="body2"
                      align={isMobile ? "center" : "left"}
                    >
                      Em andamento
                    </Typography>
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  my={isMobile ? 2 : 0}
                >
                  <Typography variant="h5" fontWeight={700}>
                    {formatToBRL(resume.operations_total || 0)} <br />
                    <Typography
                      variant="body2"
                      align={isMobile ? "center" : "left"}
                    >
                      Em operações
                    </Typography>
                  </Typography>
                </Box>
                {accessType !== null && accessType.includes("originador") ? (
                  <Box
                    display="flex"
                    flexDirection="column"
                    my={isMobile ? 2 : 0}
                  >
                    <Typography variant="h5" fontWeight={700}>
                      {formatToBRL(resume.originator_commission_total || 0)}{" "}
                      <br />
                      <Typography
                        variant="body2"
                        align={isMobile ? "center" : "left"}
                      >
                        Comissões em aberto
                      </Typography>
                    </Typography>
                  </Box>
                ) : (
                  ""
                )}
              </Box>
            </Paper>
            <Paper
              display="flex"
              width={isMobile ? "100%" : "25%"}
              flexDirection={isMobile ? "column" : "row"}
              my={2}
              justifyContent="space-between"
              minHeight="150px"
              elevation={3}
              p={2}
              scroll="auto"
            >
              <Box
                display="flex"
                flexDirection={isMobile ? "column" : "row"}
                flexWrap={isMobile ? "wrap" : "nowrap"}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  my={isMobile ? 2 : 0}
                >
                  <Icon name="excel" size="60px" viewBox="0 0 50 65" />
                  <Button color="primary" onClick={() => downloadExcel(data)}>
                    Baixar Excel
                  </Button>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  my={isMobile ? 2 : 0}
                >
                  <Icon name="pdf" size="60px" viewBox="0 0 50 65" />
                  <Button
                    color="primary"
                    onClick={() => donwloadPdf(data, tableContractingColumns)}
                  >
                    Baixar PDF
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Box>
          <Table
            title="Operações"
            data={data}
            columns={tableContractingColumns}
            options={tableContractingOptions}
          />
        </>
      )}
      {isAlert.open && (
        <Alert
          component="temporary"
          open={isAlert.open}
          severity={isAlert.status}
          message={isAlert.message}
          onClose={handleCloseSnackbar}
        />
      )}
      {isLoadingAlert && <Alert component="loading" open={isLoadingAlert} />}
    </Box>
  );
};
