import React from "react";
import { Helmet } from "react-helmet";
import { Alert, Box, Paper, Table, Loading } from "../../../components";
import {
  api,
  routeOperationsPorfolioChart,
  routeOperationsPortfolio,
} from "../../../services/api";
import { Chart } from "../../../layout/Chart";
import { tableOperationsWalletColumns } from "./props";
import { useIsMobile } from "../../../hooks/useIsMobile";
import {
  handlingErrors,
  captalizeString,
  handlingSuccess,
} from "../../../utils";
import { Empty } from "../../../layout";
import { debounceSearchRender } from "mui-datatables";

export const PortfolioPageComponent = (props) => {
  const [page, setPage] = React.useState(0);
  const [count, setCount] = React.useState(1);
  const isMobile = useIsMobile();
  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isLoadingAlert, setIsLoadingAlert] = React.useState(false);
  const [chartData, setChartData] = React.useState([]);
  const [sortOrder, setSortOrder] = React.useState({
    name: "id",
    direction: "desc",
  });
  const [isAlert, setIsAlert] = React.useState({
    open: false,
    message: "",
    status: "",
  });

  const tableOperationsWalletOptions = {
    search: false,
    viewColumns: false,
    download: false,
    print: false,
    filter: false,
    rowHover: false,
    selectableRows: "none",
    rowsPerPageOptions: [10, 25, 50],
    responsive: "vertical",
    serverSide: true,
    count: count,
    page: page,
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          setIsLoadingAlert(true);
          getData(tableState.page);
          setPage(tableState.page);
          break;
        case "changeRowsPerPage":
          setIsLoadingAlert(true);
          getData(tableState.page, tableState.rowsPerPage);
          setPage(tableState.page);
          break;
        case "search":
          setIsLoadingAlert(true);
          getData(
            tableState.page,
            tableState.rowsPerPage,
            tableState.searchText
          );
          setPage(tableState.page);
          break;
        case "sort":
          setIsLoadingAlert(true);
          getData(
            tableState.page,
            tableState.rowsPerPage,
            tableState.searchText,
            tableState.sortOrder.name,
            tableState.sortOrder.direction
          );
          setPage(tableState.page);
          break;
        default:
          return 0;
      }
    },
    customSearchRender: debounceSearchRender(1000),
    onColumnSortChange: (changedColumn, direction) => {
      let order = "desc";
      if (direction === "asc") {
        order = "asc";
      }
      setSortOrder((prevState) => ({
        ...prevState,
        name: changedColumn,
        direction: order,
      }));
    },
    sortOrder: sortOrder,
    textLabels: {
      body: {
        noMatch: "Desculpe, não encontramos nada.",
        toolTip: "Ordenar",
      },
      pagination: {
        next: "Próxima página",
        previous: "Página anterior",
        rowsPerPage: "Linhas por página:",
        displayRows: "de",
      },
    },
  };

  React.useEffect(() => {
    api
      .get(`legacy${routeOperationsPorfolioChart}`)
      .then((response) => {
        if (response.data.operations.length === 0) {
          return handlingSuccess(response, setIsAlert);
        }

        const { operations } = response.data;
        const respMap = operations.map((item, index) => {
          const split = item.date.split("-");
          const date = new Date(split[0], split[1] - 1, split[2]); // 2009-11-10
          const month = captalizeString(
            date.toLocaleString("pt-BR", { month: "short" })
          );
          return { mes: month, valor: item.value };
        });
        setChartData(respMap);
      })
      .catch((error) => {
        handlingErrors(error, setIsAlert);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  React.useEffect(() => {
    getData();
  }, []);

  const handleCloseSnackbar = () =>
    setIsAlert((prevAlert) => ({ ...prevAlert, open: false }));

  const getData = (
    page = 0,
    limit = 10,
    search = null,
    columnSort = "id",
    columnDirection = "desc"
  ) => {
    api
      .get(
        `legacy${routeOperationsPortfolio}?page=${page + 1
        }&limit=${limit}&search=${search}&sort=${columnSort}&direction=${columnDirection}`
      )
      .then((response) => {
        setCount(response.data.total);
        if (response.data.operations.length === 0) {
          return handlingSuccess(response, setIsAlert);
        }

        const responseMap = response.data.operations.map((item) => {
          const operationId = item.operationId;
          const totalAmountOwed = item.totalAmountOwed;
          const amountPaid = item.amountPaid;
          const total = Math.round((amountPaid / totalAmountOwed) * 100);
          const status = item.status;
          const viewDetails = { link: `${operationId}` };
          const objectStatus = { total: total, status: status };
          return (item = { ...item, objectStatus, ...viewDetails });
        });
        setData(responseMap);
      })
      .catch((error) => {
        handlingErrors(error, setIsAlert);
      })
      .finally(() => {
        setIsLoading(false);
        setIsLoadingAlert(false);
      });
  };

  return isLoading ? (
    <Box display="flex" height="100vh">
      <Loading />
    </Box>
  ) : (
    <Box display="block">
      <Helmet>
        <title>Operações | Originador </title>
      </Helmet>
      {data.length === 0 ? (
        <Empty>{isAlert.message}</Empty>
      ) : (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            mb={3}
            flexDirection={isMobile ? "column" : "row"}
          >
            <Paper
              width="100%"
              flexDirection={isMobile ? "column" : "row"}
              justifyContent="space-between"
              my={2}
              minHeight="400px"
              elevation={3}
              p={2}
            >
              <Box display="flex" minHeight="350px">
                <Chart.Line
                  data={chartData}
                  dataKeyXAxis="mes"
                  dataKeyArea="valor"
                />
              </Box>
            </Paper>
          </Box>
          <Table
            data={data}
            columns={tableOperationsWalletColumns}
            options={tableOperationsWalletOptions}
          />
        </>
      )}
      {isAlert.open && (
        <Alert
          component="temporary"
          open={isAlert.open}
          severity={isAlert.status}
          message={isAlert.message}
          onClose={handleCloseSnackbar}
        />
      )}
      {isLoadingAlert && <Alert component="loading" open={isLoadingAlert} />}
    </Box>
  );
};
