import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
    margin: theme.spacing(1),
  },
  select: {
    width: '30%',
    margin: theme.spacing(1)
  },
  inputSameRow: {
    width: '48%',
    maxWidth: '48%',
    margin: theme.spacing(1,0)
  },
  buttonSubmit: {
    width: '50%',
    height: '56px',
    margin: theme.spacing(1),
  },
  buttonSubmitPassword: {
    width: '100%',
    height: '56px',
    marginTop: theme.spacing(1),
  }
}));
