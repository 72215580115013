import React from "react";
import { Box } from "../../../../../components";
import { Controls } from "../../../../../components/controls";
import { api } from "../../../../../services/api";
import { stateOptions } from "../../../../../utils";

export const AddressStepCompponent = (props) => {
  const { onHandleSetValue } = props;
  const [isAlert, setIsAlert] = React.useState({
    open: false,
    message: "",
    status: "",
  });

  const handleAlert = () => props.onHandleAlert(isAlert);

  const handleGetAddress = (cep) => {
    const newValueCnpj = cep.replace(/[^0-9]/g, "");

    api
      .get(`https://viacep.com.br/ws/${newValueCnpj}/json/`)
      .then((response) => {
        const { data } = response;

        if (data.erro) {
          setIsAlert((prevAlert) => ({
            ...prevAlert,
            open: true,
            message: "Não foi possível carregar CEP, verifique os dados!",
            status: "error",
          }));
          return handleAlert();
        }

        const obj = [
          { name: "address", value: data.logradouro },
          { name: "district", value: data.bairro },
          { name: "city", value: data.localidade },
          { name: "state", value: data.uf },
        ];

        obj.forEach(({ name, value }) => onHandleSetValue(name, value));
      })
      .catch((error) => {
        handleAlert();
        console.error(error);
      });
  };

  return (
    <>
      <Controls.InputMask
        name="zipCode"
        label="CEP"
        placeholder="Digite seu cep"
        onBlur={(event) => handleGetAddress(event.target.value)}
        required
        fullWidth
        mask="99999-999"
      />
      <Controls.Input
        name="address"
        label="Endereço"
        placeholder="Digite seu endereço"
        required
        fullWidth
      />
      <Controls.Input
        name="number"
        type="number"
        label="Numero"
        placeholder="Digite o numero"
        required
        fullWidth
      />
      <Controls.Input
        name="complement"
        label="Complemento"
        placeholder="Digite o complemento"
        fullWidth
      />
      <Controls.Input
        name="district"
        label="Bairro"
        placeholder="Digite seu bairro"
        required
        fullWidth
      />
      <Controls.Input
        name="city"
        label="Cidade"
        placeholder="Digite sua cidade"
        required
        fullWidth
      />
      <Box mt={2}>
        <Controls.Select
          name="state"
          label="Estado"
          placeholder="Digite seu estado"
          size="small"
          options={stateOptions}
          required
          fullWidth
        />
      </Box>
    </>
  );
};
