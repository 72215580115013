import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import graficos from "../../assets/images/graficos.svg";
import seta from "../../assets/images/seta.svg";
import pending from "../../assets/images/pending.svg";
import { Grid } from '@material-ui/core';
import { Box } from "../../components";
import { Info } from '../../layout';
import { ForgotForm } from "./forgot";
import { SignInForm } from "./signIn";
import { RegisterForm } from "./register";
import { ResetPassword } from "./resetPassword";
import { WaitingApproval } from "./pending";

export const AuthPageComponent = (props) => {
  const { path } = useRouteMatch();
  const { location } = props;

  const switchInfoPath = () => {
    switch (location.pathname) {
      case "/auth/register":
        return (
          <Info
            image={seta}
            title="Faça seu cadastro e seja um parceiro Captalys."
            text="Aproveite as facilidades da Plataforma GO da Captalys
                  e fique em dia sobre o andamento de suas operações. 
                  Além disso você tem acesso a treinamentos, campanhas de 
                  premiação e mais."
          />
        );
        case "/auth/pending":
          return (
            <Info
              image={pending}
              title="Estamos analisando seu cadastro."
            />
          )
      default:
        return (
          <Info
            image={graficos}
            title="Boas vindas a Plataforma GO."
            text="Aproveite as facilidades da Plataforma GO da Captalys
                  e fique em dia sobre o andamento de suas operações. Além disso
                  você tem acesso a treinamentos, campanhas de premiação e mais."
          />
        );
    }
  };

  return (
    <Grid container>
      <Box
        component={Grid}
        item
        md={6}
        display={{ xs: "none", md: "flex" }}
        justifyContent="center"
        alignItems="center"
      >
        {switchInfoPath()}
      </Box>
      <Box
        component={Grid}
        item
        xs={12}
        md={6}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Switch>
          <Route exact path={path} component={SignInForm} />
          <Route path={`${path}/register`} component={RegisterForm} />
          <Route path={`${path}/forgot`} component={ForgotForm} />
          <Route path={`${path}/reset`} component={ResetPassword} />
          <Route path={`${path}/pending`} component={WaitingApproval} />
        </Switch>
      </Box>
    </Grid>
  );
};
