import axios from "axios";
import { getToken, logout } from "./auth";

//rotas api
export const routeLogin = "/login";
export const routeRegister = "/register";
export const routeForgotPassword = "/user/forgot-password";
export const routeResetPassword = "/user/password-reset";
export const routeExistsUser = "/exists-user";
export const routeClients = "/clients";
export const routeClientsCreate = "/clients/create";
export const routeUrlAuthenticated = "url-authenticated";
export const routeOperationsContracting = "/operations/contracting";
export const routeOperationsPorfolioChart = "/operations/portfolio/chart";
export const routeOperationsPortfolio = "/operations/portfolio";
export const routeOperationsPortfolioCCB = "/operations/ccb-file";
export const routeEditProfile = "/user/update/profile";
export const routeEditAddress = "/user/update/address";
export const routeEditPassword = "/user/update/password";
export const routeProfile = "/user/profile";
export const routeOriginators = "/administrative/get-registrations";
export const routeActionOriginators = "/administrative/change-status-originador";

const { REACT_APP_ENV } = process.env;

export const ENV = {};

const mountEnv = (env = 'staging')  => {
  const enviroment = env !== 'production' ? `.${env}.` : '.'

  return {
    API_URL: `https://go${enviroment}captalysplatform.io/`,
    TOMATICO_URL: `https://tomatico${enviroment}captalys.com.br/`,
    TOMATICO_LEGACY_URL: `https://tomatico${enviroment}captalys.io/`,
    CIELO_URL: `https://cielo${enviroment}captalys.com.br/`,
    CIELO_LEGACY_URL: `https://cielo${enviroment}captalys.io/`,
  }
}

const getEnv = (env) => {
  if (env === 'production') {
    return Object.assign(ENV, mountEnv('production'), { 
      TOMATICO_LEGACY_URL: 'https://app.tomatico.com/',
      CIELO_LEGACY_URL: `https://cielo.captalys.io/`,
    })
  }

  if (['staging', 'sandbox', 'development'].includes(env)) {
    return Object.assign(ENV, mountEnv(env))
  };  

  //custom env
  Object.assign(ENV, mountEnv('development'), {
    API_URL: "https://b824-177-62-149-84.ngrok.io/"
  })
}

getEnv(REACT_APP_ENV);

export const api = axios.create({
  baseURL: ENV.API_URL,
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  const url = config.url;

  if (url.includes("viacep")) return config;

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});


api.interceptors.response.use((response) => {
  return response;

}, (error) => {
  const url = window.location.href;

  if (error.response.status === 401 && !url.includes("/auth")) {
    logout();
  }

  return Promise.reject(error);
});
