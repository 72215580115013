import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
  const ChooseColor = (color, theme) => {
    return {
      'warning': theme.palette.status.warning,
      'success': theme.palette.status.success,
      'primary': theme.palette.primary.main,
      'secondary': theme.palette.secondary.main
    }[color] || theme.palette.status.main
  }

  return ({
    divider: {
      backgroundColor: props => props.color ? ChooseColor(props.color, theme) : theme.palette.status.main,
      width: "100%"
    }
  })
});
