import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme) => {
  return {
    input: {
      minHeight: "85px",
      maxWidth: "200px",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
        minHeight: "100%",
        margin: theme.spacing(1, 0),
      },
    },
  };
});
