import React from 'react';
import { Controls } from "../../../../components/controls";


export const DefaultStepComponent = (props) => {
  return(
    <>
      <Controls.InputMask
            name="cnpj"
            control={props.control}
            label="CNPJ"
            placeholder="Digite o CNPJ do cliente"
            fullWidth
            required
            mask="99.999.999/9999-99"
          />
          <Controls.Input
            name="fullName"
            control={props.control}
            label="Nome Completo"
            placeholder="Digite o nome completo do cliente"
            fullWidth
            required
          />
          <Controls.Input
            name="email"
            control={props.control}
            label="E-mail"
            placeholder="Digite o e-mail do cliente"
            fullWidth
            required
          />
          <Controls.InputMask
            name="cellphone"
            control={props.control}
            label="Celular"
            placeholder="Digite o celular do cliente"
            fullWidth
            required
            mask="(99) 99999-9999"
          />
          <Controls.Input
            name="password"
            control={props.control}
            label="Senha"
            placeholder="Digite a senha para este cliente"
            type="password"
            fullWidth
            required
            autoComplete="on"
          />
          <Controls.Input
            name="confirmPassword"
            control={props.control}
            label="Confirma senha"
            placeholder="Confirme a senha para este cliente"
            type="password"
            fullWidth
            required
            autoComplete="on"
          />
    </>
  )
}
