import { makeStyles } from '@material-ui/core';

const drawerWidth = 200;

export const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth + "px",
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth
  },
  offset: theme.mixins.toolbar,
  navbar: {
    paddingTop: '0',
  },
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.otherColors.lightBlue,
    },
  },
  listIcon: {
    color: theme.palette.primary.main,
  },
  active: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.otherColors.lightBlue,
    borderLeft: `5px solid ${theme.palette.primary.main}`,
  },
  typography: {
    color: theme.palette.otherColors.darkBlueNames
  }
}));
