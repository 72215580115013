import React from "react";
import { Button, Link as MaterialLink, Snackbar } from "@material-ui/core";
import { Paper, Typography } from "../../components";
import { useIsMobile } from "../../hooks/useIsMobile";

import { useStyles } from "./style";

export const CookiesComponent = (props) => {
  const [isCookiesAlert, setIsCookiesAlert] = React.useState(true);
  const isMobile = useIsMobile();
  const classes = useStyles();

  React.useState(() => {
    if (localStorage.getItem("accept-cookies") === "true") {
      setIsCookiesAlert(false);
    }
  }, []);

  const handleCloseCookiesAlert = () => {
    localStorage.setItem("accept-cookies", true);
    setIsCookiesAlert(false);
  };

  return (
    <Snackbar
      open={isCookiesAlert}
      onClose={() => isCookiesAlert === false}
      color="primary"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: isMobile ? "center" : "left",
      }}
    >
      <Paper
        display="flex"
        flexDirection="column"
        maxWidth="350px"
        variant="outlined"
      >
        <Typography
          component="h3"
          variant="h5"
          color="inherit"
          fontWeight={700}
          gutterBottom
        >
          Cookies
        </Typography>
        <Typography variant="body2">
          Este site utiliza cookies para te proporcionar uma <br /> melhor
          experiência. Ao continuar navegando, <br /> você aceita nossa &nbsp;
          <MaterialLink
            href="https://app.captalyx.com.br/tomatico/app_docs_politica_privacidade.pdf"
            target="_blank"
            rel="noopener noreferrer"
            underline="always"
          >
            Política de Privacidade.
          </MaterialLink>
        </Typography>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleCloseCookiesAlert}
        >
          Continuar e fechar
        </Button>
      </Paper>
    </Snackbar>
  );
};
