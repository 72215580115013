import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FormProvider, useForm } from "react-hook-form";
import {
  Button,
  Stepper,
  Step,
  StepLabel,
  Link as MaterialLink,
} from "@material-ui/core";
import { Alert, Box, Icon, Paper, Typography } from "../../../components";
import { formOptions } from "./props";
import { AddressStep, InformationStep } from "./Steps";
import { api, routeRegister } from "../../../services/api";
import { handlingErrors, handlingSuccess } from "../../../utils";

import { useStyles } from "../style";

export const RegisterFormComponent = (props) => {
  const [isAlert, setIsAlert] = React.useState({
    open: false,
    message: "",
    status: "",
  });
  const history = useHistory();
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = ["Informações", "Endereço"];

  const handleCloseSnackbar = () =>
    setIsAlert((prevAlert) => ({ ...prevAlert, open: false }));

  const onSubmit = async (data) => {
    const { cnpjCpf, fullName, username, email, cellphone, password, confirmPassword,
      zipCode, address, number, district, city, state, complement,
    } = data;

    await api.post(routeRegister, {
      cnpjCpf: cnpjCpf,
      fullName: fullName,
      username: username,
      email: email,
      cellphone: cellphone,
      password: password,
      confirmPassword: confirmPassword,
      zipCode: zipCode,
      address: address,
      number: number,
      district: district,
      city: city,
      state: state,
      complement: complement,
    })
    .then((response) => {
      handlingSuccess(response, setIsAlert);
      handleNext();
    })
    .catch((error) => {
      handlingErrors(error, setIsAlert);
    })    
  };

  const methods = useForm(formOptions(activeStep));
  const { handleSubmit, trigger, reset, setValue } = methods;

  const handleAlert = (alert) => {
    setIsAlert(alert);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <InformationStep onHandleAlert={handleAlert} />;
      case 1:
        return (
          <AddressStep
            onHandleSetValue={setValue}
            onHandleAlert={handleAlert}
          />
        );
      default:
        return "Etapa desconhecida";
    }
  }

  const handleNext = async () => {
    const isStepValid = await trigger();
    if (isStepValid) setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    reset();
    history.push("/auth");
  };

  return (
    <Box display="flex" flexDirection="column">
      <Helmet>
        <title>Registro | Originador </title>
      </Helmet>
      <Box display="flex" my={2} maxWidth="350px">
        {isAlert.open && (
          <Alert
            open={isAlert.open}
            severity={isAlert.status}
            message={isAlert.message}
            onClose={handleCloseSnackbar}
          />
        )}
      </Box>
      <Paper display="flex" flexDirection="column" maxWidth="350px" square>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <Box display="flex" flexDirection="column" my={3}>
            <Box display="flex" flexDirection="column">
              <Icon
                style={{ fontSize: 120 }}
                name="happyFace"
                color="primary"
              />
              <Typography fontWeight="bold">Tudo certo!</Typography>
            </Box>

            <Box mt={3}>
              <Button variant="contained" color="primary" onClick={handleReset}>
                Acessar portal
              </Button>
            </Box>
          </Box>
        ) : (
          <FormProvider {...methods}>
            <form className={classes.form} noValidate>
              {getStepContent(activeStep)}
              <Box display="flex" justifyContent="space-evenly" mt={3}>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.submit}
                  variant="contained"
                >
                  Voltar
                </Button>
                {activeStep === steps.length - 1 ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit(onSubmit)}
                    className={classes.submit}
                  >
                    Enviar
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.submit}
                  >
                    Próximo
                  </Button>
                )}
              </Box>
            </form>
          </FormProvider>
        )}
      </Paper>
      <Box display="flex" my={2} justifyContent="flex-start" maxWidth="350px">
        <Typography variant="body2" component="p" color="textSecondary">
          Já tenho meu usuário e senha? <br />
          <MaterialLink
            component={Link}
            to="/auth"
            variant="body2"
            color="primary"
          >
            Faça Login!
          </MaterialLink>
        </Typography>
      </Box>
    </Box>
  );
};
