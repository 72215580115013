import React from "react";
import { Helmet } from 'react-helmet';
import { useParams } from "react-router";
import { api } from "../../../services/api";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { Chart } from "../../../layout/Chart";
import {
  Alert,
  Box,
  Loading,
  Paper,
  Table,
  Typography,
} from "../../../components";
import { ResumeTemplate } from "./Templates";
import { tableDetailsColumns, tableDetailsOptions } from "./props";
import { captalizeString, handlingErrors } from "../../../utils";

import { ThemeProvider } from "@material-ui/styles";
import { themeDetails } from "./style";

export const CustomersDetailsPageComponent = (props) => {
  const [data, setData] = React.useState([]);
  const [chartData, setChartData] = React.useState([]);
  const [isAlert, setIsAlert] = React.useState({
    open: false,
    message: "",
    status: "",
  });
  const [isLoading, setIsLoading] = React.useState(true);
  const isMobile = useIsMobile();
  const { id } = useParams();
  const routeClientStatement = `/client/${id}/statement`;
  const routeClientChart = `/client/${id}/statement/chart`;

  const handleCloseSnackbar = () =>
    setIsAlert((prevAlert) => ({ ...prevAlert, open: false }));

  React.useEffect(() => {
    api
      .get(routeClientStatement)
      .then((response) => {
        const { statement } = response.data;
        setData(statement);
      })
      .catch((error) => {
        handlingErrors(error, setIsAlert);
      })
      .finally(() => setIsLoading(false));
  }, [routeClientStatement]);

  React.useEffect(() => {
    api
      .get(routeClientChart)
      .then((response) => {
        const { months } = response.data;
        months.sort((a, b) => (a.date > b.date ? 1 : -1));
        const responseMap = months.map((item, index) => {
          const split = item.date.split("-");
          const date = new Date(split[0], split[1] - 1, split[2]); // 2009-11-10

          const month = captalizeString(
            date.toLocaleString("pt-BR", { month: "short" })
          );
          return { month: month, valor: item.value };
        });
        setChartData(responseMap);
      })
      .catch((error) => {
        handlingErrors(error, setIsAlert)
      })
      .finally(() => setIsLoading(false));
  }, [routeClientChart]);

  return isLoading ? (
    <Box display="flex" height="100vh">
      <Loading />
    </Box>
  ) : (
    <Box px={1} py={2} width="100%">
      <Helmet>
        <title>Detalhes | Originador </title>
      </Helmet>
      {isAlert.open && (
        <Alert
          component="temporary"
          open={isAlert.open}
          severity={isAlert.status}
          message={isAlert.message}
          onClose={handleCloseSnackbar}
        />
      )}
      {data.length === 0 && (
        <Box>
          <Alert message="O cliente ainda não pagou nenhuma fatura." severity="warning" />
        </Box>
      )}
      <Box my={2}>
        <Typography variant="h5" fontWeight={700}>
          Extrato
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        mb={3}
        flexDirection={isMobile ? "column" : "row"}
      >
        <Paper
          width={isMobile ? "100%" : "55%"}
          flexDirection={isMobile ? "column" : "row"}
          justifyContent="space-between"
          my={2}
          minHeight="360px"
          elevation={3}
          p={2}
        >
          <Box display="flex">
            <Chart.Line
              data={chartData}
              dataKeyXAxis="month"
              dataKeyArea="valor"
            />
          </Box>
        </Paper>
        <Paper
          width={isMobile ? "100%" : "40%"}
          flexDirection="column"
          my={2}
          justifyContent="space-between"
          elevation={3}
          minHeight="360px"
          p={2}
        >
          <ResumeTemplate />
        </Paper>
      </Box>
      <ThemeProvider theme={themeDetails}>
        <Table
          data={data}
          columns={tableDetailsColumns}
          options={tableDetailsOptions}
        />
      </ThemeProvider>
    </Box>
  );
};
