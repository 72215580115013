import React from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  ListItemIcon,
} from "@material-ui/core";
import { Icon, Typography } from "../../../components";
import { DialogMenu } from "./Dialog";

const object = {
  edit: {
    title: "Editar",
    icon: "edit",
    color: "default",
  },
  // delete: {
  //   title: "Remover",
  //   icon: "delete",
  //   color: "default",
  // },
  // reset: {
  //   title: "Resetar",
  //   icon: "reset",
  //   color: "default",
  // },
};

const copyObjectValues = Object.values(object);

export const MenuPageComponent = (props) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [option, setOption] = React.useState("");
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDialog = () => {
    setOption("");
    setOpenDialog(false);
  };

  const handleClickIcon = (props) => {
    setOption(props);
    setOpenDialog(true);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        disabled={props.approvedProposal === true}
      >
        <Icon
          name="more"
          color={props.approvedProposal === true ? "default" : "primary"}
        />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {copyObjectValues.map((option) => (
          <MenuList key={option.title} selected={option === "Pyxis"}>
            <MenuItem onClick={() => handleClickIcon(option.title)}>
              <ListItemIcon>
                <Icon name={option.icon} color={option.color} />
              </ListItemIcon>
              <Typography variant="inherit">{option.title}</Typography>
            </MenuItem>
          </MenuList>
        ))}
        <DialogMenu
          open={openDialog}
          onClose={handleCloseDialog}
          option={option}
          ecid={props.ecid}
          cnpj={props.cnpj}
        />
      </Menu>
    </div>
  );
};
