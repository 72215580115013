import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Stepper,
  Step,
  StepLabel,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Box, Alert } from "../../components";
import { useForm, FormProvider } from "react-hook-form";
import { useIsMobile } from "../../hooks/useIsMobile";
import { useStyles } from "./style";
import { formOptions } from "./props";
import { DefaultStep, BankStep } from "./Steps";
import { api, routeClientsCreate } from "../../services/api";
import { GlobalContext } from "../../context";

export const DialogRegisterCustomerComponent = (props) => {
  const isMobile = useIsMobile();
  const classes = useStyles();
  const history = useHistory();
  const [activeStep, setActiveStep] = React.useState(0);
  const global = React.useContext(GlobalContext);
  const [steps, setSteps] =  React.useState(['Informações']);
  const [isDisabledButton, setIsDisabledButton] = React.useState(false);
  const [isLoadingAlert, setIsLoadingAlert] = React.useState(false);


  const methods = useForm(formOptions(activeStep));
  const { handleSubmit, trigger, reset } = methods;

  React.useEffect(() => {
    if(sessionStorage.getItem('product') === 'cielo'){
      setSteps(['Informações', 'Banco'])
    }
  },[props.access])

 
  function getStepContent(step) {
    switch (step) {
      case 0:
        return <DefaultStep />;
      case 1:
        return <BankStep />;
      default:
        return "Etapa desconhecida";
    }
  }

  const handleNext = async () => {
    const isStepValid = await trigger();
    if (isStepValid) setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    reset();
  };

  const onSubmit = async (data) => {
    setIsDisabledButton(true);
    setIsLoadingAlert(true);
    const { cnpj, fullName, email, cellphone, password, confirmPassword, ec, bank, bankBranch, bankAccount, accountDigit } =
      data;
    console.log(data);
    
    await api
      .post(routeClientsCreate, {
        cnpj: cnpj,
        nome: fullName,
        email: email,
        celular: cellphone,
        senha: password,
        confirmaSenha: confirmPassword,
        produto: sessionStorage.getItem('product'),
        ec: ec,
        bank: bank,
        bankBranch: bankBranch.toString(),
        bankAccount: bankAccount.toString(),
        accountDigit: accountDigit
      })
      .then((response) => {
        props.onHandligSuccess(response);
        global.setToggleAddCustomer((prev) => !prev);
        props.onClose();
        handleReset();
        history.push("/customers");
      })
      .catch((error) => {
        props.onHandligError(error);
      })
      .finally(() => {
        setIsDisabledButton(false);
        setIsLoadingAlert(false)
      })
  };

  return (
    <Dialog
      fullScreen={isMobile}
      open={props.open}
      onClose={props.onClose}
      scroll="body"
      title="Preencha as informações abaixo"
      maxWidth="xs"
      type="submit"
    >
      <DialogTitle className={classes.title}>Preencha os dados</DialogTitle>
      <DialogContent>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
  
          <FormProvider {...methods}>
            <form className={classes.form} noValidate>
              {getStepContent(activeStep)}
              <Box display="flex" justifyContent="space-between" mt={3} className={classes.actions}>
                <Button
                  disabled={activeStep === 0 || isDisabledButton}
                  onClick={handleBack}
                  className={classes.submit}
                  variant="contained"
                >
                  Voltar
                </Button>
                {activeStep === steps.length - 1 ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit(onSubmit)}
                    className={classes.submit}
                    disabled={isDisabledButton}
                  >
                    Enviar
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.submit}
                  >
                    Próximo
                  </Button>
                )}
              </Box>
            </form>
          </FormProvider>
       
      </DialogContent>
      <Box display="flex" className={classes.actions}>
        <Button onClick={props.onClose} color="primary">
          Fechar
        </Button>
      </Box>
      {isLoadingAlert && <Alert component="loading" open={isLoadingAlert} />}
    </Dialog>
  );
};
