import React from "react";
import { Button } from "@material-ui/core";
import { Box, Typography } from "../../components";
import { useStyles } from "./style";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import { useIsMobile } from "../../hooks/useIsMobile";
import { GlobalContext } from "../../context";

export const EmptyComponent = (props) => {
  const isMobile = useIsMobile();
  const classes = useStyles();
  const global = React.useContext(GlobalContext);

  const handleOpenDialogAddCustomer = () => {
    global.setOpenDialogAddCustomer(true);
  };

  return (
    <Box display="flex" py={8}>
      <Box
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        maxWidth="800px"
      >
        <AcUnitIcon className={classes.icon} color="primary" />
        <Box display="column" mx={2}>
          <Typography
            variant="h4"
            component="h1"
            fontWeight={800}
            className={classes.title}
            align={isMobile ? "center" : "left"}
            gutterBottom
          >
            Tudo muito calmo por aqui...
          </Typography>
          {props.children ? (
            <Typography
              variant="body1"
              component="p"
              gutterBottom
              align={isMobile ? "center" : "left"}
            >
              {props.children}
            </Typography>
          ) : (
            <Typography
              variant="body1"
              component="p"
              gutterBottom
              align={isMobile ? "center" : "left"}
            >
              Vamos descongelar seus ganhos? Comece agora mesmo a originar novos
              {isMobile ? "" : <br />}
              clientes para exibirmos todas as informações!
            </Typography>
          )}
          {props.button ? (
            <Box
              display="flex"
              justifyContent={isMobile ? "center" : "flex-start"}
              my={2}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenDialogAddCustomer}
              >
                Quero originar
              </Button>
            </Box>
          ) : (
            ""
          )}
        </Box>
      </Box>
    </Box>
  );
};
