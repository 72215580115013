import XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { formatToBRL } from "brazilian-values";
import { captalizeString } from "../../utils"

export const downloadExcel = (data) => {
  const cloneItens = data.map((item) => {
    const progression = item.progression
    const name = item.name ? captalizeString(item.name) : "-"
    const value = formatToBRL(item.value)

    return { ...item, ...progression, name, value}
  })

  const getSelectedValues = cloneItens.map((item) => {
    const { 
      id,
      name,
      value,
      cadastro,
      conexao_fonte,
      analise,
      documentos,
      desembolso   
    } = item;

    return { id, name, value, cadastro, conexao_fonte, analise, documentos, desembolso };
  });

  const workSheet = XLSX.utils.json_to_sheet(getSelectedValues);
  const workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, workSheet, "operações");
  XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
  XLSX.writeFile(workBook, "Operações.xlsx");
};

export const donwloadPdf = (data, columns) => {
  const cloneItens = data.map((item) => {
    const progression = item.progression
    const name = item.name ? captalizeString(item.name) : "-"
    const value = formatToBRL(item.value)

    return { ...item, ...progression, name, value}
  })

  const getKeysPosition = Object.keys(cloneItens[0]);

  getKeysPosition.splice(3, 4);

  const getSelectedValues = cloneItens.map((item) => {
    const { 
      id,
      name,
      value,
      cadastro,
      conexao_fonte,
      analise,
      documentos,
      desembolso
    } = item;

    return { id, name, value, cadastro, conexao_fonte, analise, documentos, desembolso };
  });
 
  const doc = new jsPDF();
  doc.text("Operações", 20, 10);
  doc.autoTable({
    theme: "grid",
    columns: getKeysPosition,
    body: getSelectedValues.map((item) => Object.values(item)),
  });
  doc.save("Operações.pdf");
};
