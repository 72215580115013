import React from "react";
import { Tooltip, Typography } from "@material-ui/core";
import { Icon } from "../";

export const TooltipComponent = (props) => {
  return props.title ? (
    <Tooltip
      title={props.title}
      arrow
      placement={props.placement || "right"}
      {...props}
    >
      <Typography component="span">
        {props.children ? (
          props.children
        ) : (
          <Icon name="help" fontSize="inherit" />
        )}
      </Typography>
    </Tooltip>
  ) : null;
};
