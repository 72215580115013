import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export const validationSchema = Yup.object().shape({
  user: Yup.string()
    .required("O campo usuário obrigatório."),
  password: Yup.string()
    .required("O campo senha é obrigatório")
    .min(8, "Por favor digite uma senha com no mínimo 8 caracteres."),
});

export const formOptions = {
  resolver: yupResolver(validationSchema),
  defaultValues: {
    user: "",
    password: "",
  },
};
