import { Typography } from "../../../components";
import { formatToBRL } from "brazilian-values";
import { captalizeString } from "../../../utils";
import { AccessPortal } from "../../../layout";

export const tableContractingColumns = [
  {
    name: "id",
    label: "ID",
  },
  {
    name: "cnpj",
    label: "CNPJ",
  },
  {
    name: "corporate_name",
    label: "Estabalecimento comercial",
    options: {
      customBodyRender: (name) => {
        if (name) {
          return (
            <Typography variant="body2">{captalizeString(name)}</Typography>
          );
        }

        return <Typography variant="subtitle2">{name || "-"}</Typography>;
      },
    },
  },
  {
    name: "name",
    label: "Nome",
    options: {
      customBodyRender: (name) => {
        if (name) {
          return (
            <Typography variant="body2">{captalizeString(name)}</Typography>
          );
        }

        return <Typography variant="subtitle2">{name || "-"}</Typography>;
      },
    },
  },
  {
    name: "value",
    label: "Valor da operação",
    options: {
      sort: false,
      customBodyRender: (valor) => {
        return (
          <Typography variant="subtitle2">
            {formatToBRL(valor) || "-"}
          </Typography>
        );
      },
    },
  },
  {
    name: "state",
    label: "Status",
    options: {
      sort: false,
      customBodyRender: (value) => {
        return <Typography>{value || "-"}</Typography>;
      },
    },
  },
  {
    name: "viewDetails",
    label: " ",
    options: {
      sort: false,
      customBodyRender: (value) => <AccessPortal value={value} />,
    },
  },
];
