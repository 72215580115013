import React from "react";
import { Box, Paper, Icon, Alert, Loading } from "../../components";
import { ResumeOriginator, ScrollButton } from "../../layout";
import { Select as MaterialSelect, FormControl, InputLabel, MenuItem, TextField, IconButton, Button } from "@material-ui/core";
import { useIsMobile } from "../../hooks/useIsMobile";
import debounce from "lodash.debounce";
import { Helmet } from "react-helmet";
import { useStyles } from "./style";
import { handlingErrors } from "../../utils";
import { api, routeOriginators, routeActionOriginators} from "../../services/api";

export const OriginatorPageComponent = () => {
  const [actionbarToTop, setActionbarToTop] = React.useState(undefined);
  const [selectStatus, setSelectStatus] = React.useState("pending");
  const [isSticky, setIsSticky] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isLoadingAlert, setIsLoadingAlert] = React.useState(false);
  const [originatorCrumbs, setOriginatorCrumbs] = React.useState({});
  const [isSimpleAlert, setIsSimpleAlert] = React.useState(false);
  const [isHandlingAlert, setIsHandlingAlert] = React.useState({
    open: false,
    message: "",
    status: "",
  });
  const [isActionAlert, setIsActionAlert] = React.useState({
    open: false,
    message: "",
  });
  const [searchTerm, setSearchTerm] = React.useState("");
  const [originators, setOriginators] = React.useState({
    data: [],
    page: 1,
    limit: 5,
    total: 0,
  });
  const [disabledButton, setDisabledButton] = React.useState({
    approval: false,
    reproval: false,
  });
  const classes = useStyles();
  const isMobile = useIsMobile();

  const getSticky = React.useCallback(
    (e) => {
      const scrollTop = window.scrollY;
      if (scrollTop >= actionbarToTop - 10) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    },
    [actionbarToTop]
  );

  const handleSelectChange = (event) => {
    const name = event.target.value;

    switch (name) {
      case "approved":
        setDisabledButton({ approval: true, reproval: false });
        break;
      case "declined":
        setDisabledButton({ approval: false, reproval: true });
        break;
      default:
        setDisabledButton({ approval: false, reproval: false });
        break;
    }
    setOriginators((prev) => ({ ...prev, data: [], page: 1 }));
    setSelectStatus(name);
    setIsLoading(true);
  };

  const handleLoadMore = () => {
    setIsLoadingAlert(true);
    setOriginators((prev) => ({ ...prev, page: prev.page + 1 }));
  };
  const handleSearchChange = (e) => {
    setIsLoadingAlert(true);
    setOriginators((prev) => ({ ...prev, data: [], page: 1 }));
    return setSearchTerm(e.target.value.toLowerCase());
  };

  const handleAction = (action) => {
    setOriginators((prev) => ({ ...prev, data: [] }));
    setOriginatorCrumbs(action);
    let message = "Usuário reprovado! Verifique-o no filtro correspondente.";

    if (action.next_status === "approved") {
      message = "Usuário aprovado! Verifique-o no filtro correspondente.";
    }

    api
      .post(routeActionOriginators, {
        user_id: action.id,
        status: action.next_status,
      })
      .then((response) => {
        setIsActionAlert((prev) => ({ ...prev, open: true, message: message }));
      })
      .catch((error) => {
        handlingErrors(error, setIsHandlingAlert);
      });
  };
  const handleUndoAction = () => {
    setOriginators((prev) => ({ ...prev, data: [] }));
    setIsActionAlert(false);
    api
      .post(routeActionOriginators, {
        user_id: originatorCrumbs.id,
        status: originatorCrumbs.prev_status,
      })
      .then((response) => {
        setIsSimpleAlert(true);
      })
      .catch((error) => {
        handlingErrors(error, setIsHandlingAlert);
      });
  };

  const handleCloseAlert = () => {
    setIsActionAlert(false);
    setIsSimpleAlert(false);
  };

  const handleCloseSnackbar = () =>
    setIsHandlingAlert((prev) => ({ ...prev, open: false }));

  const debouncedResults = React.useMemo(() => {
    return debounce(handleSearchChange, 1000);
  }, []);

  React.useEffect(() => {
    if (document.querySelector(".actionbar") === null) return;

    const actionbarElement = document
      .querySelector(".actionbar")
      .getBoundingClientRect();

    setActionbarToTop(actionbarElement.top);
  }, [originators.data]);

  React.useEffect(() => {
    if (!actionbarToTop) return;
    window.addEventListener("scroll", getSticky);
    return () => {
      window.removeEventListener("scroll", getSticky);
    };
  }, [actionbarToTop, getSticky]);

  React.useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  React.useEffect(() => {
    api
      .get(
        `${routeOriginators}/${selectStatus}?search=${searchTerm}&limit=${originators.limit}&page=${originators.page}`
      )
      .then((response) => {
        response.data.originators.forEach((item) => {
          Object.keys(item).forEach((value) => {
            if (item[value] === null) item[value] = "";
            if (item[value] === "") item[value] = "-";
          });
        });
        setOriginators((prev) => ({
          ...prev,
          data: [...prev.data, ...response.data.originators],
          total: response.data.total,
        }));
      })
      .catch((error) => {
        handlingErrors(error, setIsHandlingAlert);
      })
      .finally(() => {
        setIsLoading(false);
        setIsLoadingAlert(false);
      });
  }, [
    selectStatus,
    originators.page,
    originators.limit,
    searchTerm,
    isActionAlert.open,
  ]);

  return isLoading ? (
    <Box display="flex" height="100vh">
      <Loading />
    </Box>
  ) : (
    <Box display="flex" flexDirection="column" p={4}>
       <Helmet>
        <title>Originadores | Originador</title>
      </Helmet>
      <ScrollButton />
      <Box
        display="flex"
        justifyContent="space-between"
        component={isSticky ? Paper : Box}
        elevation={5}
        flexDirection={isMobile ? "column" : "row"}
        className={`actionbar ${isSticky ? classes.sticky : ""}`}
      >
        <Box display="flex" justifyContent="flex-start" pb={2}>
          <FormControl>
            <InputLabel id="filtro">Filtro</InputLabel>
            <MaterialSelect
              value={selectStatus}
              onChange={handleSelectChange}
              labelId="filtro"
            >
              <MenuItem value="pending">Pendente(s)</MenuItem>
              <MenuItem value="approved">Aprovado(s)</MenuItem>
              <MenuItem value="declined">Reprovado(s)</MenuItem>
            </MaterialSelect>
          </FormControl>
        </Box>
        <Box
          display="flex"
          justifyContent={isMobile ? "flex-start" : "flex-end"}
        >
          <TextField
            id="search"
            placeholder="Pesquisar"
            onChange={debouncedResults}
            InputProps={{
              startAdornment: (
                <IconButton>
                  <Icon name="search" color="primary" />
                </IconButton>
              ),
            }}
          />
        </Box>
      </Box>
      <Box display="flex" flexDirection="column">
        {originators.data.map((item, index) => {
          return (
            <ResumeOriginator
              data={item}
              key={index}
              disabledButton={disabledButton}
              onHandleAction={handleAction}
            />
          );
        })}
      </Box>
      {originators.data.length < originators.total && searchTerm !== null && (
        <Box>
          <Button variant="contained" color="primary" onClick={handleLoadMore}>
            Carregar mais
          </Button>
        </Box>
      )}
      {isLoadingAlert && <Alert component="loading" open={isLoadingAlert} />}
      {isSimpleAlert && (
        <Alert
          component="simple"
          open={isSimpleAlert}
          onClose={handleCloseAlert}
          message="Ação desfeita"
        />
      )}
      {isActionAlert.open && (
        <Alert
          component="action"
          open={isActionAlert.open}
          onClose={handleCloseAlert}
          message={isActionAlert.message}
          onClick={handleUndoAction}
        />
      )}
      {isHandlingAlert.open && (
        <Alert
          component="temporary"
          open={isHandlingAlert.open}
          severity={isHandlingAlert.status}
          message={isHandlingAlert.message}
          onClose={handleCloseSnackbar}
        />
      )}
    </Box>
  );
};
