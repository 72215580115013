import { Typography } from "../../../components";
import styled from "styled-components";
import { formatToBRL, formatToDate } from "brazilian-values";

//Criado suporte em styles components por que nao conseguimos utilizar os
//hooks dentro do arquivo js
const SupportBox = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 960px) {
    justify-content: flex-start;
  }
`;

export const tableDetailsOptions = {
  download: false,
  filter: false,
  print: false,
  rowHover: false,
  rowsPerPageOptions: [10, 25, 50],
  responsive: "vertical",
  search: false,
  selectableRows: "none",
  viewColumns: false,

  textLabels: {
    body: {
      noMatch: "Desculpe, não encontramos nada",
      toolTip: "Ordenar",
    },
    pagination: {
      next: "Próxima página",
      previous: "Página anterior",
      rowsPerPage: "Linhas por página:",
      displayRows: "de",
    },
  },
};

export const tableDetailsColumns = [
  {
    name: "date",
    options: {
      sort: false,
      customHeadLabelRender: () => (
        <Typography align="center" variant="body1" fontWeight={700}>
          Data
        </Typography>
      ),
      customBodyRender: (value) => {
        return (
          <SupportBox>
            <Typography variant="body1">
              {formatToDate(new Date(value + " 00:00:00")) || "-"}
            </Typography>
          </SupportBox>
        );
      },
    },
  },
  {
    name: "salesAmount",
    options: {
      sort: false,
      customHeadLabelRender: () => (
        <Typography align="center" fontWeight={700} variant="body1">
          Valor das vendas
        </Typography>
      ),
      customBodyRender: (value) => {
        return (
          <SupportBox>
            <Typography variant="body1">{formatToBRL(value) || "-"}</Typography>
          </SupportBox>
        );
      },
    },
  },
  {
    name: "paymentAmount",
    options: {
      sort: false,
      customHeadLabelRender: () => (
        <Typography align="center" fontWeight={700} variant="body1">
          Valor de pagamento
          <br />
          <Typography component="span" variant="body2">
            12% das liquidações
          </Typography>
        </Typography>
      ),
      customBodyRender: (value) => {
        return (
          <SupportBox>
            <Typography variant="body1" color="error">
              {formatToBRL(value) || "-"}
            </Typography>
          </SupportBox>
        );
      },
    },
  },
  {
    name: "netValue",
    options: {
      sort: false,
      hint: "?",
      customHeadLabelRender: () => (
        <Typography align="center" fontWeight={700} variant="body1">
          Valor líquido
        </Typography>
      ),
      customBodyRender: (value) => {
        return (
          <Typography color="primary" variant="body1">
            {formatToBRL(value) || "-"}
          </Typography>
        );
      },
    },
  },
];

export const dataLineChartColumns = [
  { name: "Jan", R$: 650 },
  { name: "Fev", R$: 1500 },
  { name: "Mar", R$: 2500 },
  { name: "Abr", R$: 2000 },
  { name: "Mai", R$: 500 },
  { name: "Jun", R$: 1890 },
];
