import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
 button: {
  backgroundColor: theme.palette.secondary.main,
  "&:hover": {
    backgroundColor: theme.palette.secondary.dark,
  },
  boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
  position: "fixed",
  bottom: "30px",
  right: "10px",
  zIndex: 1100
},
 icon:{
   color: theme.palette.primary.contrastText
 }
}));
