import { Checkbox } from './Checkbox';
import { Input } from './Input';
import { InputMask } from './InputMask';
import { Select } from './Select';

export const Controls ={
  Checkbox,
  Input,
  InputMask,
  Select
}

