import React from "react";
import { Card, CardContent, CardMedia } from "@material-ui/core";
import { Typography } from '../../components'

import { useStyles } from "./style";

export const InfoComponent = (props) => {
  const classes = useStyles();
  return (
    <Card className={classes.box} elevation={0}>
      {props.image && (
        <CardMedia
          component="img"
          alt="Imagem Ilustrativa"
          image={props.image}
          title="Imagem Ilustrativa"
          className={classes.img}
        />
      )}
      <CardContent>
        {props.title && (
          <Typography
            gutterBottom
            variant="h4"
            component="h2"
            color="primary"
            fontWeight={700}
            align="left"
          >
            {props.title}
          </Typography>
        )}
        {props.text && (
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            align="justify"
          >
            {props.text}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};
