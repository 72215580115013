import React from "react";
import { Tabs } from "../../components";
import { ContractingPage } from "./contracting";
import { PortfolioPage } from "./porfolio";

export const OperationsPageComponent = (props) => {
  
  return (
    <Tabs
      tabs={[
        {
          title: "Em contratação",
          content: <ContractingPage />,
          routerName: "contracting",
        },
        {
          title: "Operações em carteira",
          content: <PortfolioPage />,
          routerName: "portfolio",
        },
      ]}
      {...props}
    />
  );
};
