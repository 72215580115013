import { createTheme } from "@material-ui/core/styles";

export const themeDetails = createTheme({
  palette: {
    primary: {
      main: "#2CB567",
      light: "#56C385",
      dark: "#1E7E48",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#EBEBEB !important",
      },
    },
  },
});
