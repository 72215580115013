import React from "react";
import { Tabs } from "../../components";
import { ProfilePage } from "./Profile";
import { AddressPage } from "./Address";
import { PasswordPage } from "./Password";

export const EditPageComponent = (props) => {
  
  return (
    <Tabs
      tabs={[
        {
          title: "Perfil",
          content: <ProfilePage />,
          routerName: "profile",
        },
        {
          title: "Endereço",
          content: <AddressPage />,
          routerName: "address",
        },
        {
          title: "Senha",
          content: <PasswordPage />,
          routerName: "password",
        },
      ]}
      {...props}
    />
  );
};
