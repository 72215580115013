import React from "react";
import { Helmet } from 'react-helmet';
import { useLocation, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Link as MaterialLink, IconButton, InputAdornment } from "@material-ui/core";
import { Controls } from "../../../components/controls";
import { Alert, Box, Icon,Paper, Typography } from "../../../components";
import { formOptions } from "./props";
import { api, routeResetPassword } from "../../../services/api";
import { handlingErrors, handlingSuccess } from "../../../utils";

import { useStyles } from "../style";

export const ResetPasswordComponent = (props) => {
  const [isAlert, setIsAlert] = React.useState({
    open: false,
    message: "",
    status: "",
  });

  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmNewPassword, setConfirmNewShowPassword] = React.useState(false);

  const handleClickshowNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleClickshowConfirmNewPassword = () => setConfirmNewShowPassword(!showConfirmNewPassword);


  const handleCloseSnackbar = () =>
    setIsAlert((prevAlert) => ({ ...prevAlert, open: false }));

  const history = useHistory();
  const { search } = useLocation();
  const classes = useStyles();

  const searchParams = new URLSearchParams(search);
  const token = searchParams.get("token");

  const { handleSubmit, control } = useForm(formOptions);

  React.useEffect(() => {
    api
      .get(routeResetPassword, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .catch((error) => {
        handlingErrors(error, setIsAlert);
        
        history.push("/auth");
      })
  }, [token, history]);

  const onSubmit = (data) => {
    api
      .post(routeResetPassword, {
        password: data.password,
        passwordConfirmation: data.confirmPassword,
      }, {
        headers: {
          Authorization: `Bearer ${token}`   
        }
      })
      .then((response) => {
        handlingSuccess(response, setIsAlert);

        history.push("/auth");
      })
      .catch((error) => {
        handlingErrors(error, setIsAlert);
      });
  };

  return (
    <Box display="flex" flexDirection="column">
      <Helmet>
        <title>Nova senha | Originador </title>
      </Helmet>
      <Box maxWidth="350px" width="100%" mb={2}>
        {isAlert.open && (
          <Alert
            component="temporary"
            open={isAlert.open}
            severity={isAlert.status}
            message={isAlert.message}
            onClose={handleCloseSnackbar}
          />
        )}
      </Box>
      <Paper display="flex" flexDirection="column" maxWidth="350px" square>
        <Typography
          component="h3"
          variant="h5"
          color="inherit"
          fontWeight={700}
          align="center"
          gutterBottom
        >
          Nova senha
        </Typography>
        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controls.Input
            name="password"
            control={control}
            label="Senha"
            placeholder="Digite sua nova senha"
            fullWidth
            required
            type={showNewPassword ? "text" : "password"}
            autoComplete="on"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Alternar a visibilidade da senha"
                    onClick={handleClickshowNewPassword}
                  >
                    {showNewPassword ? (
                      <Icon name="showPassword" />
                    ) : (
                      <Icon name="hidePassword" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Controls.Input
            name="confirmPassword"
            control={control}
            label="Confirma senha"
            placeholder="Confirme sua nova senha"
            fullWidth
            required
            type={showConfirmNewPassword ? "text" : "password"}
            autoComplete="on"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Alternar a visibilidade da senha"
                    onClick={handleClickshowConfirmNewPassword}
                  >
                    {showConfirmNewPassword ? (
                      <Icon name="showPassword" />
                    ) : (
                      <Icon name="hidePassword" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Enviar
          </Button>
        </form>
      </Paper>
      <Box display="flex" my={2} justifyContent="flex-start" maxWidth="350px">
        <MaterialLink
          component={Link}
          to="/auth"
          variant="body2"
          color="primary"
        >
          Voltar para o login!
        </MaterialLink>
      </Box>
    </Box>
  );
};
