import React from "react";

import { api } from "../../services/api";
import { Link as MaterialLink } from "@material-ui/core";
import { Alert, Box, Typography } from "../../components";
import { captalizeString, handlingErrors } from "../../utils";
import { DialogAdditionalInformation } from "./Dialog";

import { useStyles } from "./style"

export const AdditionalInformationComponent = (props) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [client, setClient] = React.useState([]);
  const [company, setCompany] = React.useState([]);
  const [address, setAddress] = React.useState([]);
  const [bank, setBank] = React.useState([]);
  const [isAlert, setIsAlert] = React.useState({
    open: false,
    message: "",
    status: "",
  });

  const { cnpj } = props;

  const routeAdditionalInformation = `/client/${cnpj}/additional-information`;
  const classes = useStyles();

  const handleCloseSnackbar = () =>
    setIsAlert((prevAlert) => ({ ...prevAlert, open: false }));

  const handleAdditionalInformation = () => {
    setOpenDialog(true);

    api
      .get(routeAdditionalInformation)
      .then((response) => {
        setClient(response.data.client);
        setCompany(response.data.company);
        setAddress(response.data.company.address);
        setBank(response.data.bank);
      })
      .catch((error) => {
        handlingErrors(error, setIsAlert);
      });
  };

  const handleCloseDialog = () => setOpenDialog(false);

  return (
    <Box>
      {props.name ? (
        <MaterialLink
          underline="always"
          color="primary"
          onClick={handleAdditionalInformation}
          className={classes.materialLink}
        >
          {captalizeString(props.name)}
        </MaterialLink>
      ) : (
        <Typography variant="body2">{props.name}</Typography>
      )}
      <DialogAdditionalInformation
        open={openDialog}
        onClose={handleCloseDialog}
        client={client}
        company={company}
        address={address}
        bank={bank}
      />
      <Alert
        component="temporary"
        open={isAlert.open}
        severity={isAlert.status}
        message={isAlert.message}
        onClose={handleCloseSnackbar}
      />
    </Box>
  );
};
