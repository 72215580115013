import React from "react";

import { Box } from "@material-ui/core";

export const BoxComponent = (props) => {
  const component =
    props.display === "flex" ? (
      <Box
        display="flex"
        width={props.width || "100%"}
        justifyContent={props.justifyContent || "center"}
        alignItems={props.alignItems || "center"}
        flexDirection={props.flexDirection || "row"}
        {...props}
      >
        {props.children}
      </Box>
    ) : (
      <Box {...props}>{props.children}</Box>
    );

  return component;
};
