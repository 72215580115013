import React from 'react';
import { SvgIcon } from '@material-ui/core';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import ArrowUpwardOutlinedIcon from '@material-ui/icons/ArrowUpwardOutlined';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import HourglassEmptyOutlinedIcon from '@material-ui/icons/HourglassEmptyOutlined';
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import RecentActorsOutlinedIcon from '@material-ui/icons/RecentActorsOutlined';
import BubbleChartOutlinedIcon from '@material-ui/icons/BubbleChartOutlined';
import MenuBookOutlinedIcon from '@material-ui/icons/MenuBookOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import SentimentDissatisfiedOutlinedIcon from '@material-ui/icons/SentimentDissatisfiedOutlined';
import EmojiPeopleOutlinedIcon from "@material-ui/icons/EmojiPeopleOutlined";
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import GetAppIcon from '@material-ui/icons/GetApp';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import GroupWorkOutlinedIcon from '@material-ui/icons/GroupWorkOutlined';
import SearchOutlined from '@material-ui/icons/SearchOutlined';
import PhoneInTalkOutlinedIcon from '@material-ui/icons/PhoneInTalkOutlined';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import QueryBuilderOutlinedIcon from '@material-ui/icons/QueryBuilderOutlined';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import TagFacesOutlinedIcon from '@material-ui/icons/TagFacesOutlined';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { ReactComponent as ExcelIcon } from "../../assets/icons/excel.svg";
import { ReactComponent as PDFIcon } from "../../assets/icons/pdf.svg";
import { ReactComponent as DocumentIcon } from "../../assets/icons/document.svg";
import { ReactComponent as GetnetIcon } from "../../assets/icons/getnet.svg";
import { ReactComponent as CielotIcon } from "../../assets/icons/cielo.svg";
import { ReactComponent as RedeIcon } from "../../assets/icons/rede.svg";
import { useStyles } from './style';

export const IconComponent = (props) => {
  const { color, ...rest } = props;
  const classes = useStyles({
    color,
    size: props.size,
  });

  const ChooseIcon = (name) => {
    return {
      'help': <HelpOutlineOutlinedIcon className={classes.icon} {...rest} />,
      'close': <CloseOutlinedIcon className={classes.icon} {...rest} />,
      'check': <CheckOutlinedIcon className={classes.icon} {...rest} />,
      'happyFace': <TagFacesOutlinedIcon className={classes.icon} {...rest} />,
      'arrowBack': <ArrowBackOutlinedIcon className={classes.icon} {...rest} />,
      'arrowUp': <ArrowUpwardOutlinedIcon className={classes.icon} {...rest} />,
      'search': <SearchOutlined className={classes.icon} {...rest} />,
      'warning': <ErrorOutlineIcon className={classes.icon} {...rest} />,
      'download': <GetAppIcon className={classes.icon} {...rest} />,
      'remove': <HighlightOffOutlinedIcon className={classes.icon} {...rest} />,
      'time': <HourglassEmptyOutlinedIcon className={classes.icon}  {...rest} />,
      'home': <HomeOutlinedIcon className={classes.icon}  {...rest} />,
      'operations': <BubbleChartOutlinedIcon className={classes.icon}  {...rest} />,
      'customers': <RecentActorsOutlinedIcon className={classes.icon}  {...rest} />,
      'training': <MenuBookOutlinedIcon className={classes.icon}  {...rest} />,
      'logout': <ExitToAppOutlinedIcon className={classes.icon}  {...rest} />,
      'people': <EmojiPeopleOutlinedIcon className={classes.icon} {...rest} />,
      'unlike': <ThumbDownAltOutlinedIcon className={classes.icon} {...rest} />,
      'like': <ThumbUpAltOutlinedIcon className={classes.icon} {...rest} />,
      'sad': <SentimentDissatisfiedOutlinedIcon className={classes.icon}  {...rest} />,
      'whatsapp': <WhatsAppIcon className={classes.icon}  {...rest} />,
      'email': <MailOutlineIcon className={classes.icon}  {...rest} />,
      'workgroup': <GroupWorkOutlinedIcon className={classes.icon}  {...rest} />,
      'phone': <PhoneInTalkOutlinedIcon className={classes.icon}  {...rest} />,
      'person': <PersonOutlineIcon className={classes.icon}  {...rest} />,
      'edit': <EditIcon className={classes.icon}  {...rest} />,
      'delete': <DeleteIcon className={classes.icon}  {...rest} />,
      'more': <MoreVertIcon className={classes.icon}  {...rest} />,
      'arrowRight': <KeyboardArrowRight className={classes.icon}  {...rest} />,
      'arrowLeft': <KeyboardArrowLeft className={classes.icon}  {...rest} />,
      'clock': <QueryBuilderOutlinedIcon className={classes.icon}  {...rest} />,
      'showPassword': <VisibilityIcon className={classes.icon}  {...rest} />,
      'hidePassword': <VisibilityOffIcon className={classes.icon}  {...rest} />,
      'reset': <RotateLeftIcon className={classes.icon}  {...rest} />,
      'excel': <SvgIcon component={ExcelIcon} viewBox={props.viewBox ? props.viewBox : '0 0 50 50'} className={classes.svgIconFile} {...rest} />,
      'pdf': <SvgIcon component={PDFIcon} viewBox={props.viewBox ? props.viewBox : '0 0 50 50'} className={classes.svgIconFile} {...rest} />,
      'document': <SvgIcon component={DocumentIcon} viewBox={props.viewBox ? props.viewBox : '0 0 50 50'} className={classes.svgIconFile} {...rest} />,
      'getnet': <SvgIcon component={GetnetIcon} viewBox={props.viewBox ? props.viewBox : '0 0 50 50'} className={classes.svgIconFile} {...rest} />,
      'cielo': <SvgIcon component={CielotIcon} viewBox={props.viewBox ? props.viewBox : '0 0 50 50'} className={classes.svgIconFile} {...rest} />,
      'rede': <SvgIcon component={RedeIcon} viewBox={props.viewBox ? props.viewBox : '0 0 50 50'} className={classes.svgIconFile} {...rest} />,
    }[name] || <RemoveOutlinedIcon className={classes.icon} />
  }

  return ChooseIcon(props.name)
}
