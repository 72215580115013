import React from "react";
import { Link } from "react-router-dom";
import { AppBar, Toolbar, IconButton, Link as MaterialLink } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import logo from "../../assets/icons/logo_go.svg";

import { useStyles } from "./style";
import { isAuthenticated } from "../../services/auth";

export const NavbarComponent = (props) => {
  const classes = useStyles({ drawerWidth: props.drawerWidth });
  const url = props.location.pathname;

  return (
    <>
      <AppBar className={classes.appBar} elevation={props.elevation}>
        <Toolbar className={classes.toolBar}>
          {url.includes("/auth") ? (
            ""
          ) : (
            <IconButton
              color="inherit"
              aria-label="menu"
              className={classes.menuButton}
              onClick={() => props.openDrawer()}
            >
              <MenuIcon />
            </IconButton>
          )}
          <MaterialLink component={Link} to={isAuthenticated ? "/customers" : "/auth"}>
            <img
              width="100px"
              height="40px"
              src={logo}
              alt="Logo Grupos de Originadores"
              className={classes.logo}
            />
          </MaterialLink>
          
        </Toolbar>
      </AppBar>
      <div className={classes.offset}></div>
    </>
  );
};
