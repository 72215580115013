import React from "react";
import { useHistory } from "react-router-dom";
import { api, routeOperationsPortfolioCCB } from "../../services/api";
import { Alert, Box, Icon } from "../../components";
import { handlingErrors } from "../../utils";
import { useIsMobile } from "../../hooks/useIsMobile";
import { IconButton } from "@material-ui/core";

export const DownloadCCBComponent = (props) => {
  const [isLoadingAlert, setIsLoadingAlert] = React.useState(false);
  const [isAlert, setIsAlert] = React.useState({
    open: false,
    message: "",
    status: "",
  });
  const { operationId } = props;
  const history = useHistory();

  const handleCloseSnackbar = () =>
    setIsAlert((prevAlert) => ({ ...prevAlert, open: false }));

  const handleClick = () => {
    setIsLoadingAlert(true);

    api
      .get(`${routeOperationsPortfolioCCB}/${operationId}`)
      .then((response) => {
        const { link } = response.data;

        history.push(link);
      })
      .catch((error) => {
        handlingErrors(error, setIsAlert);
      })
      .finally(() => setIsLoadingAlert(false));
  };

  return (
    <Box display="flex" justifyContent={useIsMobile ? "flex-start" : "center"}>
      {operationId === "" ? (
        <IconButton disabled>
          <Icon name="download" color="disabled"  />
        </IconButton>
      ) : (
        <IconButton onClick={handleClick}>
          <Icon name="download" color="primary" />
        </IconButton>
      )}
      <Alert
        component="temporary"
        open={isAlert.open}
        severity="error"
        message="Não foi possivel fazer download do arquivo."
        onClose={handleCloseSnackbar}
      />
      {isLoadingAlert && <Alert component="loading" open={isLoadingAlert} />}
    </Box>
  );
};
