import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { isCPF } from "brazilian-values";

export const validationSchema = Yup.object().shape({
  user: Yup.string()
    .required("O campo nome completo é obrigatório")
    .test("fullName", "Por favor digite o nome completo.", (val) => {
      const splitted = val.split(" ");
      const sizeSplitted = splitted.length - 1;
      return sizeSplitted !== 0 && splitted[1] !== "";
    }),
  cpf: Yup.string()
    .required("O campo cpf é obrigatório.")
    .test("cpf", "Por favor digite um cpf válido.", (val) => {
      return isCPF(val);
    }),
  usuario: Yup.string().required("O campo usuário obrigatório."),
  email: Yup.string()
    .required("O campo e-mail é obrigatório.")
    .email("Por favor digite um e-mail válido."),
  whatsapp: Yup.string()
    .required("O campo celular é obrigatório.")
    .test("lenght", "Por favor digite um telefone válido.", (val) => {
      if (val === null) return false;

      const valLengthWithoutDashes = val.replace(/-|_/g, "").length;
      return valLengthWithoutDashes === 14;
    }),
});

export const formOptions = {
  resolver: yupResolver(validationSchema),
  defaultValues: {
    user: "",
    usuario: "",
    email: "",
    cpf: "",
    whatsapp: "",
  },
};
