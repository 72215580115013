import { makeStyles } from '@material-ui/core';

const ChooseColor = (color,theme) => {
  return {
    'warning': theme.palette.warning.light,
    'warningDark': theme.palette.warning.dark,
    'success':  theme.palette.status.success,
    'primary': theme.palette.primary.main,
    'secondary': theme.palette.secondary.main,
    'error':  theme.palette.error.main,
  }[color] ||  theme.palette.status.main
}

export const useStyles = makeStyles((theme) => ({
  icon:{
    color: props => ChooseColor(props.color,theme),
  },
  svgIconFile:{
    width: props => props.size || '50px',
    height: props => props.size || '50px'
  }
}));
