import { createPortal } from "react-dom";
import {
  formatToPhone,
  formatToCPFOrCNPJ,
  formatToCNPJ,
} from "brazilian-values";

export const formatValue = (value, format) => {
  if (!value) return "-";

  const object = {
    cnpj: formatToCNPJ(value),
    cpf: formatToCPFOrCNPJ(value),
    captalize: captalizeString(value),
    telephone: formatToPhone(value),
  };

  return object[format] || value;
};

export const ParseDate = (date) =>
  date === null ? "00/00/0000" : date.toLocaleDateString();

export const captalizeString = (name) => {
  if (name === null || name === "") return name;
  const exceptions = ["e", "de", "da", "do", "das", "dos"];
  const words = name.match(/\b\w+\b/g);
  return words
    .map((e, i) =>
      exceptions.indexOf(e) === -1 || i === 0
        ? e[0].toUpperCase() + e.slice(1).toLowerCase()
        : e
    )
    .join(" ");
};

export const formatDate = (value) => {
  return value.replace(/-/g, "/");
};

export const handlingSuccess = (response, setIsAlert) => {
  const { message, status } = response.data;
  setIsAlert((prevAlert) => ({
    ...prevAlert,
    open: true,
    message: message,
    status: status,
  }));
};

export const handlingErrors = (error, setIsAlert) => {
  if (error.response) {
    const { message, status } = error.response.data;
    setIsAlert((prevAlert) => ({
      ...prevAlert,
      open: true,
      message: message,
      status: status,
      url: "",
    }));
  } else if (error.request) {
    setIsAlert((prevAlert) => ({
      ...prevAlert,
      open: true,
      message: "A conexão com servidor falhou!",
      status: "error",
    }));
  } else {
    setIsAlert((prevAlert) => ({
      ...prevAlert,
      open: true,
      message: "Não foi possível carregar os dados!",
      status: "error",
    }));
  }
};

export function ReactPortal({ children, domNode }) {
  return createPortal(children, domNode);
}

export const stateOptions = [
  { name: "Acre", value: "AC" },
  { name: "Alagoas", value: "AL" },
  { name: "Amapá", value: "AP" },
  { name: "Amazonas", value: "AM" },
  { name: "Bahia", value: "BA" },
  { name: "Ceará", value: "CE" },
  { name: "Distrito Federal", value: "DF" },
  { name: "Espírito Santo", value: "ES" },
  { name: "Goiás", value: "GO" },
  { name: "Maranhão", value: "MA" },
  { name: "Mato Grosso", value: "MT" },
  { name: "Mato Grosso do Sul", value: "MS" },
  { name: "Minas Gerais", value: "MG" },
  { name: "Pará", value: "PA" },
  { name: "Paraíba", value: "PB" },
  { name: "Paraná", value: "PR" },
  { name: "Pernambuco", value: "PE" },
  { name: "Piauí", value: "PI" },
  { name: "Rio de Janeiro", value: "RJ" },
  { name: "Rio Grande do Norte", value: "RN" },
  { name: "Rio Grande do Sul", value: "RS" },
  { name: "Rondônia", value: "RO" },
  { name: "Roraima", value: "RR" },
  { name: "Santa Catarina", value: "SC" },
  { name: "São Paulo", value: "SP" },
  { name: "Sergipe", value: "SE" },
  { name: "Tocantins", value: "TO" },
  { name: "Estrageiro", value: "Estrangeiro" },
];

export const bankOptions = [
  { value: "336", name: "336 - C6 BANK" },
  { value: "341", name: "341 - ITAÚ UNIBANCO BM S.A." },
  { value: "237", name: "237 - BCO BRADESCO S.A." },
  { value: "104", name: "104 - CAIXA ECONOMICA FEDERAL" },
  { value: "033", name: "033 - BCO SANTANDER (BRASIL) S.A." },
  { value: "001", name: "001 - BCO DO BRASIL S.A." },
  { value: "003", name: "003 - BANCO DA AMAZONIA S.A" },
  { value: "004", name: "004 - Banco do Nordeste do Brasil S.A" },
  {
    value: "021",
    name: "021 - BANESTES - BANCO DO ESTADO DO ESPIRITO SANTO S.A",
  },
  { value: "041", name: "041 - BCO BANRISUL" },
  { value: "047", name: "047 - BCO DO ESTADO DE SERGIPE S.A. - BANESE" },
  { value: "070", name: "070 - BRB BANCO DE BRASILIA SA" },
  { value: "077", name: "077 - BCO INTERMEDIUM S.A." },
  { value: "082", name: "082 - BANCO TOPAZIO S.A" },
  {
    value: "085",
    name: "085 - Cooperativa Central de Credito Urbano-CECRED",
  },
  { value: "097", name: "097 - CCC NOROESTE BRASILEIRO LTDA" },
  {
    value: "133",
    name: "133 - CRESOL CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS DE CRÉDITO",
  },
  { value: "136", name: "136 - UNICRED COOPERATIVA LTDA" },
  { value: "197", name: "197 - STONE PAGAMENTOS S.A" },
  { value: "208", name: "208 - BANCO BTG PACTUAL S.A" },
  { value: "212", name: "212 - BANCO ORIGINAL S/A" },
  { value: "213", name: "213 - BCO ARBI S.A" },
  { value: "218", name: "218 - BONSUCESSO S.A." },
  { value: "260", name: "260 - NU PAGAMENTOS S.A. - NUBANK" },
  { value: "310", name: "310 - VORTX DTVM LTDA" },
  { value: "329", name: "329 - QI SCD S.A." },
  { value: "363", name: "363 - SINGULARE" },
  { value: "422", name: "422 - BCO SAFRA S.A." },
  { value: "600", name: "600 - BANCO LUSO BRASILEIRO S.A" },
  { value: "611", name: "611 - BANCO PAULISTA" },
  { value: "634", name: "634 - BANCO TRIANGULO S.A" },
  { value: "637", name: "637 - BANCO SOFISA S.A" },
  { value: "655", name: "655 - BANCO VOTORANTIM S.A" },
  { value: "748", name: "748 - BCO COOPERATIVO SICREDI S.A." },
  { value: "756", name: "756 - BCO COOPERATIVO DO BRASIL S.A. - BANCOOB" },
];
