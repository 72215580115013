import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
  return {
    actions: {
      padding: theme.spacing(1, 0, 3, 0),
    },
    title: {
      color: theme.palette.primary.main,
    },
    form: {
      width: "100%",
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(2, 0, 0,),
    },
    input: {
      width: '32%',
    },
    digit:{
      width:'20%',
    },
    select: {
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  };
});
