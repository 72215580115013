import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, IconButton, InputAdornment, Link as MaterialLink } from "@material-ui/core";
import { Helmet } from 'react-helmet';
import { Controls } from "../../../components/controls";
import { Alert, Box, Icon, Paper, Typography } from "../../../components";
import { formOptions } from "./props";
import { api, routeLogin } from "../../../services/api";
import { handlingErrors } from "../../../utils";
import { useStyles } from "../style";


export const SignInFormComponent = (props) => {
  const [isAlert, setIsAlert] = React.useState({
    open: false,
    message: "",
    status: "",
  });
  const [showPassword, setShowPassword] = React.useState(false);
  const classes = useStyles();
  const history = useHistory();

  const { handleSubmit, control } = useForm(formOptions);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const onSubmit = async (data) => {
    const { user, password } = data;

    await api
      .post(routeLogin, {}, {
        auth: { 
          username: user,
          password: password,
        }
      })
      .then((response) => {
        const { registration_status } = response.data;
        if (registration_status === "pending") {
          history.push("/auth/pending");
          return sessionStorage.setItem("status", response.data.registration_status);
        }
        
        sessionStorage.setItem("name", response.data.name);
        sessionStorage.setItem("token", response.data.token);
        sessionStorage.setItem("access_type", response.data.roles.access_type);
        sessionStorage.setItem("product", response.data.product);

        history.push("/customers");
      })
      .catch((error) => {
        handlingErrors(error, setIsAlert);
      });
  };

  return (
    <Box display="flex" flexDirection="column">
      <Helmet>
        <title>Login | Originador </title>
      </Helmet>
      <Box display="flex" my={2} maxWidth="350px">
        {isAlert.open && (
          <Alert severity={isAlert.status}>{isAlert.message}</Alert>
        )}
      </Box>
      <Paper display="flex" flexDirection="column" maxWidth="350px">
        <Typography
          component="h3"
          variant="h5"
          color="inherit"
          fontWeight={700}
        >
          Login
        </Typography>
        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controls.Input
            name="user"
            control={control}
            label="Usuário"
            placeholder="Digite seu usuário"
            fullWidth
            required
            autoComplete="on"
          />
          <Controls.Input
            name="password"
            control={control}
            label="Senha"
            placeholder="Digite sua senha"
            type={showPassword ? "text" : "password"}
            fullWidth
            required
            autoComplete="on"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Alternar a visibilidade da senha"
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? <Icon name="showPassword" /> : <Icon name="hidePassword" />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Entrar
          </Button>
          <Box align="center">
            <MaterialLink component={Link} to="/auth/forgot" variant="body2">
              Esqueceu a senha?
            </MaterialLink>
          </Box>
        </form>
      </Paper>
      <Box display="flex" my={2} justifyContent="flex-start" maxWidth="350px">
        <Typography variant="subtitle2" component="p" color="textSecondary">
          Ainda não fez o primeiro acesso?
          <br />
          <MaterialLink component={Link} to="/auth/register" variant="body2">
            Comece agora!
          </MaterialLink>
        </Typography>
      </Box>
    </Box>
  );
};
