import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Pages } from './pages';
import { GlobalStorage } from './context';

function App() {

  return (
    <BrowserRouter>
      <CssBaseline />
      <GlobalStorage>
        <Pages />
      </GlobalStorage>
    </BrowserRouter>
  );
}

export default App;
