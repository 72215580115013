import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  AppBar,
  Button,
  Toolbar,
  Container,
  IconButton,
  Typography,
} from "@material-ui/core";
import { captalizeString } from "../../utils";
import { Box, Icon } from "../../components";
import { useStyles } from "./style";


export const TopbarComponent = (props) => {
  const location = useLocation();
  const { pathname } = location;
  const history = useHistory();


  const classes = useStyles({ drawerWidth: props.drawerWidth });
  return (
    <AppBar position="fixed" className={classes.topbar}>
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" width="auto">
              {pathname.includes("details") && (
                <IconButton onClick={() => history.goBack()}>
                  <Icon name="arrowBack" color="primary" />
                </IconButton>
              )}
              {pathname.includes("/customers/details/") &&
                sessionStorage.getItem("tableName") !== null ? (
                  <Typography className={classes.typography} variant="h6">
                    {captalizeString(sessionStorage.getItem("tableName"))}
                  </Typography>
              ) : (
                ""
              )}
            </Box>
            <Box>
              <Button
                variant="contained"
                onClick={props.handleClickTopbarButton}
                color="primary"
              >
                Cadastrar novo cliente
              </Button>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
