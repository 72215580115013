import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  // cpf: Yup.string().required("O campo cpf é obrigatório."),
  email: Yup.string()
    .required("O campo e-mail é obrigatório.")
    .email("Por favor digite um e-mail válido."),
  cellphone: Yup.string()
    .required("O campo celular é obrigatório.")
    .test("lenght", "Por favor digite um telefone válido.", (val) => {
      const valLengthWithoutDashes = val.replace(/-|_/g, "").length;
      return valLengthWithoutDashes === 14;
    }),
  bank: Yup.string()
    .typeError("É obrigatório escolher um banco")
    .required("O campo banco é obrigatório."),
  bankBranch: Yup.number()
    .typeError("O campo deve ser numérico")
    .required("O campo agência é obrigatório."),
  bankAccount: Yup.number()
    .typeError("O campo deve ser numérico")
    .required("O campo conta é obrigatório."),
  accountDigit: Yup.string()
    .typeError("O campo dígito é obrigatório.")
    .required("O campo dígito é obrigatório.")
    .max(1, "Apenas um dígito"),
});

export const formOptions = {
  resolver: yupResolver(validationSchema),
  defaultValues: {
    // cpf: "",
    email: "",
    cellphone: "",
    bank: "",
    bankBranch: "",
    bankAccount: "",
    accountDigit: "",
  },
};
