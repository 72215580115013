import React from "react";
import { Button } from "@material-ui/core";
import { Box, Typography } from "../../../../components";

export const DeleteMenuComponent = (props) => {
  return (
    <Box display="flex" flexDirection="column">
      <Box mt={1}>
        <Typography fontWeight="bold" variant="h5">
          Excluir cliente
        </Typography>
      </Box>
      <Box my={4}>
        <Typography variant="h6">
          Você deseja realmente excluir esse cliente?
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" mb={1}>
        <Button variant="contained" onClick={props.handleCloseDialog}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={props.handleCloseDialog}
        >
          Enviar
        </Button>
      </Box>
    </Box>
  );
};
