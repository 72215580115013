import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("O campo e-mail é obrigatório.")
    .email("Por favor digite um e-mail válido."),
});

export const formOptions = {
  resolver: yupResolver(validationSchema),
  defaultValues: {
    email: "",
  },
};
