import React from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { Button } from "@material-ui/core";
import { Controls } from "../../../components/controls";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { formOptions } from "./props";
import { Alert, Box, Loading, Paper, Typography } from "../../../components";
import { api, routeEditProfile, routeProfile } from "../../../services/api";
import { handlingSuccess, handlingErrors } from "../../../utils";
import { formatToCPF } from "brazilian-values";

import { useStyles } from "../style";

export const ProfilePageComponent = () => {
  const [isSaved, setIsSaved] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const { handleSubmit, control, setValue } = useForm(formOptions);
  const classes = useStyles();
  const isMobile = useIsMobile();

  const [isAlert, setIsAlert] = React.useState({
    open: false,
    message: "",
    status: "",
  });

  const handleCloseSnackbar = () =>
    setIsAlert((prevAlert) => ({ ...prevAlert, open: false }));

  React.useEffect(() => {
    api
      .get(routeProfile)
      .then((response) => {
        const { user } = response.data;


        //Trocando todos os values "null" por "vazio"
        Object.keys(user).forEach((value) => {
          if (user[value] === null) user[value] = "";
        });

        const obj = [
          { name: "user", value: user.nome },
          { name: "usuario", value: user.username },
          { name: "whatsapp", value: user.celular },
          { name: "cpf", value: formatToCPF(user.cpf)},
          { name: "email", value: user.email },
        ];

        obj.forEach(({ name, value }) => setValue(name, value));
      })
      .catch((error) => {
        handlingErrors(error, setIsAlert);
      })
      .finally(() => setIsLoading(false));
  }, [isSaved, setValue]);

  const onSubmit = async (data) => {
    const { user, usuario, whatsapp, cpf, email } = data;

    api
      .post(routeEditProfile, {
        nome: user,
        username: usuario,
        celular: whatsapp,
        cpf: cpf,
        email: email,
      })
      .then((response) => {
        handlingSuccess(response, setIsAlert);
        setIsSaved(true);

        sessionStorage.setItem("name", response.data.name);
      })
      .catch((error) => {
        handlingErrors(error, setIsAlert);
      });
  };

  return isLoading ? (
    <Box display="flex" height="100vh">
      <Loading />
    </Box>
  ) : (
    <Box display="flex" flexDirection="column">
      <Helmet>
        <title>Editar perfil | Originador </title>
      </Helmet>
      {isAlert.open && (
        <Alert
          component="temporary"
          open={isAlert.open}
          severity={isAlert.status}
          message={isAlert.message}
          onClose={handleCloseSnackbar}
        />
      )}
      <Box display="flex" justifyContent="initial">
        <Typography fontWeight="bold">Editar perfil</Typography>
      </Box>
      <Paper width="100%" mt={4}>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" my={2}>
            <Box display="flex" flexDirection="column" maxWidth="800px">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                flexDirection={isMobile ? "column" : "row"}
              >
                <Controls.Input
                  name="user"
                  label="Nome"
                  placeholder="Digite seu nome"
                  size="medium"
                  control={control}
                  className={classes.input}
                  required
                />
                <Controls.Input
                  name="usuario"
                  label="Usuário"
                  placeholder="Digite seu usuário"
                  size="medium"
                  control={control}
                  className={classes.input}
                  required
                />
                <Controls.InputMask
                  name="whatsapp"
                  label="WhatsApp"
                  placeholder="Digite seu WhatsApp"
                  size="medium"
                  control={control}
                  className={classes.input}
                  mask={["(99) 99999-9999"]}
                  required
                />
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                flexDirection={isMobile ? "column" : "row"}
              >
                <Controls.InputMask
                  name="cpf"
                  label="CPF"
                  placeholder="Digite seu cpf"
                  size="medium"
                  control={control}
                  className={classes.input}
                  mask={["999.999.999-99"]}
                  required
                />
                <Controls.Input
                  name="email"
                  label="E-mail"
                  placeholder="Digite seu e-mail"
                  size="medium"
                  control={control}
                  className={classes.input}
                  required
                />
              </Box>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                className={classes.buttonSubmit}
              >
                Salvar alterações
              </Button>
            </Box>
          </Box>
        </form>
      </Paper>
    </Box>
  );
};
