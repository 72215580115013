import { Box, Typography } from "../../../../../components";
import { formatValue } from "../../../../../utils";

export const BusinessInformationComponent = (props) => {
  const { cnpj, corporate_name, telephone } = props.dataBusiness;
  const { zipcode, public_area, neighborhood, city, state } =
    props.dataBusiness.address;

  return (
    <Box display="flex" flexDirection="column" my={2}>
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Typography variant="body1" fontWeight={700}>
          CNPJ:
        </Typography>
        <Typography>{formatValue(cnpj, "cnpj")}</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Typography variant="body1" fontWeight={700}>
          Razão Social:
        </Typography>
        <Typography>{formatValue(corporate_name, "captalize")}</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Typography variant="body1" fontWeight={700}>
          Telefone:
        </Typography>
        <Typography>{formatValue(telephone, "telephone")}</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Typography variant="body1" fontWeight={700}>
          CEP:
        </Typography>
        <Typography>{formatValue(zipcode, "zipCode")}</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Typography variant="body1" fontWeight={700}>
          Logradouro:
        </Typography>
        <Typography>{formatValue(public_area, "captalize")}</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Typography variant="body1" fontWeight={700}>
          Bairro:
        </Typography>
        <Typography>{formatValue(neighborhood, "captalize")}</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Typography variant="body1" fontWeight={700}>
          Cidade:
        </Typography>
        <Typography>{formatValue(city, "captalize")}</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Typography variant="body1" fontWeight={700}>
          Estado:
        </Typography>
        <Typography>{state ? state : "-"}</Typography>
      </Box>
    </Box>
  );
};
