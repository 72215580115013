import React from "react";
import { Controller } from "react-hook-form";
import {
  FormControl,
  Select as MaterialSelect,
  MenuItem,
  InputLabel,
  FormHelperText,
} from "@material-ui/core";

export const SelectComponent = React.forwardRef((props, ref) => {
  const { label, control, name, options } = props;
  return (
    <Controller
      control={control}
      name={name}
      ref={ref}
      render={({
        field: { onChange, value, name, ref },
        fieldState: { error },
      }) => {
        return (
          <FormControl
            variant="outlined"
            {...props}
            error={error?.message ? true : false}
          >
            <InputLabel>{label}</InputLabel>
            <MaterialSelect
              name={name}
              inputRef={ref}
              onChange={(value) => {
                props.onChange && props.onChange(value) //retorna o valor para o select no component
                onChange(value) //sem a linha de cima apenas retorna o valor para a lib e é impossivel pegar no component
              }}
              value={value ? value : ""}
              label={label}
            >
              <MenuItem value="">Selecione</MenuItem>
              {options.map((item, index) => (
                <MenuItem value={item.value} key={index}>
                  {item.name}
                </MenuItem>
              ))}
            </MaterialSelect>
            {error && <FormHelperText>{error?.message}</FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
});
