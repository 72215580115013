import React from "react";
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Link as MaterialLink } from "@material-ui/core";
import { Controls } from "../../../components/controls";
import { Alert, Box, Paper, Typography } from "../../../components";
import { formOptions } from "./props";
import { useStyles } from "../style";
import { api, routeForgotPassword } from "../../../services/api";
import { handlingErrors, handlingSuccess } from "../../../utils";

export const ForgotFormComponent = () => {
  const [isAlert, setIsAlert] = React.useState({
    open: false,
    message: "",
    status: "",
  });
  const [email , setEmail] = React.useState("");

  const handleCloseSnackbar = () =>
    setIsAlert((prevAlert) => ({ ...prevAlert, open: false }));

  const classes = useStyles();

  const { handleSubmit, control, reset } = useForm(formOptions);

  const onSubmit = (data) => {
    api
      .post(routeForgotPassword, {
        email: data.email,
      })
      .then((response) => {
        setEmail(data.email);
        handlingSuccess(response, setIsAlert);
        reset();
      })
      .catch((error) => {
        setEmail("");
        handlingErrors(error, setIsAlert);
      });
  };

  return (
    <Box display="flex" flexDirection="column">
      <Helmet>
        <title>Esqueci senha | Originador </title>
      </Helmet>
      <Box maxWidth="350px" width="100%">
        {isAlert.open && (
          <Alert
            open={isAlert.open}
            severity={isAlert.status}
            onClose={handleCloseSnackbar}
          >
            {isAlert.message}
            {<Typography fontWeight={700}>{email}</Typography>}
          </Alert>
        )}
      </Box>
      <Paper display="flex" flexDirection="column" maxWidth="350px" square mt={2}>
        <Typography
          component="h3"
          variant="h5"
          color="inherit"
          fontWeight={700}
          align="center"
          gutterBottom
        >
          Esqueci minha senha
        </Typography>
        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controls.Input
            name="email"
            control={control}
            label="E-mail"
            placeholder="Digite seu e-mail"
            fullWidth
            required
            autoComplete="on"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Enviar
          </Button>
        </form>
      </Paper>
      <Box display="flex" my={2} justifyContent="flex-start" maxWidth="350px">
        <Typography variant="body2" component="p" color="textSecondary">
          Já tenho meu usuário e senha? <br />
          <MaterialLink
            component={Link}
            to="/auth"
            variant="body2"
            color="primary"
          >
            Faça Login!
          </MaterialLink>
        </Typography>
      </Box>
    </Box>
  );
};
