import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const validationSchema = [
  Yup.object().shape({
    cnpj: Yup.string().required("O campo CNPJ é obrigatório."),
    fullName: Yup.string()
      .required("O campo nome completo é obrigatório.")
      .test("fullName", "Por favor digite o nome completo.", (val) => {
        const splitted = val.split(" ");
        const sizeSplitted = splitted.length - 1;
        return sizeSplitted !== 0 && splitted[1] !== "";
      }),
    email: Yup.string()
      .required("O campo e-mail é obrigatório.")
      .email("Por favor digite um e-mail válido."),

    cellphone: Yup.string()
      .required("O campo celular é obrigatório.")
      .test("lenght", "Por favor digite um telefone válido.", (val) => {
        const valLengthWithoutDashes = val.replace(/-|_/g, "").length;
        return valLengthWithoutDashes === 14;
      }),
    password: Yup.string()
      .required("O campo senha é obrigatório")
      .min(8, "Por favor digite uma senha com no mínimo 8 caracteres."),
    confirmPassword: Yup.string()
      .required("O campo confirme a senha é obrigatório")
      .oneOf(
        [Yup.ref("password"), null],
        "As senhas não conferem. Por favor repita a senha."
      ),
  }),
  Yup.object({
    ec: Yup.string().required("O campo ec é obrigatório."),
    bank: Yup.string().required("O campo banco é obrigatório."),
    bankBranch: Yup.number()
      .typeError("O campo deve ser numérico")
      .required("O campo agência é obrigatório."),
    bankAccount: Yup.number()
      .typeError("O campo deve ser numérico")
      .required("O campo conta é obrigatório."),
    accountDigit: Yup.string()
      .required("O campo dígito é obrigatório.")
      .max(1, "Apenas um dígito"),
  }),
];

export const formOptions = (activeStep) => {
  return {
    shouldUnregister: false,
    resolver: yupResolver(validationSchema[activeStep]),
    defaultValues: {
      cnpj: "",
      cpf: "",
      fullName: "",
      email: "",
      cellphone: "",
      password: "",
      confirmPassword: "",
      ec: "",
      bank: "",
      bankBranch: "",
      bankAccount: "",
      accountDigit: "",
    },
  };
};
