import React from 'react';

import { Box, Progress } from '../../components';

export const LoadingComponent = (props) => {

  return (
    <Box display="flex" >
      <Progress size="80px" name="circular" />
    </Box>
  )
}
