import React from 'react';

import MUIDataTable from "mui-datatables";
import { tableDefaultColumns, tableDefaultOptions } from './props';


export const TableComponent = (props) => {
  return (
    <MUIDataTable
      title={props.title ? props.title : undefined}
      data={props.data}
      columns={props.columns ? props.columns : tableDefaultColumns}
      options={props.options ? props.options : tableDefaultOptions}
    />
  );
}
