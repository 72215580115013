import React from "react";
import { TextField } from "@material-ui/core";
import { Controller } from "react-hook-form";
import { mask as masker, unMask } from "remask";

export const InputMaskComponent = React.forwardRef((props, ref) => {
  const {
    name,
    variant,
    label,
    placeholder,
    margin,
    size,
    fullWidth,
    required,
    control,
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      ref={ref}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { error },
      }) => {
        const handleChange = (e) => {
          const originalValue = unMask(e.target.value);
          const maskedValue = masker(originalValue, props.mask);
          onChange(maskedValue);
        };

        return (
          <TextField
            variant={variant || "outlined"}
            label={label}
            name={name}
            placeholder={placeholder}
            margin={margin || "normal"}
            size={size || "small"}
            fullWidth={fullWidth}
            required={required}
            error={error !== undefined ? true : false}
            helperText={error?.message}
            inputRef={ref}
            value={value}
            onChange={handleChange}
            onBlur={onBlur}
            mask={props.mask}
            {...props}
          />
        );
      }}
    />
  );
});
