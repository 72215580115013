import React from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { Button } from "@material-ui/core";
import { Controls } from "../../../components/controls";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { formOptions } from "./props";
import { Alert, Box, Loading, Paper, Typography } from "../../../components";
import { api, routeEditAddress, routeProfile } from "../../../services/api";
import { handlingSuccess, handlingErrors, stateOptions } from "../../../utils";

import { useStyles } from "../style";
import { formatToCEP } from "brazilian-values";

export const AddressPageComponent = () => {
  const [isSaved, setIsSaved] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const isMobile = useIsMobile();

  const { handleSubmit, control, setValue } = useForm(formOptions);

  const classes = useStyles();

  const [isAlert, setIsAlert] = React.useState({
    open: false,
    message: "",
    status: "",
  });

  const handleCloseSnackbar = () =>
    setIsAlert((prevAlert) => ({ ...prevAlert, open: false }));

  const handleGetAddress = (cep) => {
    const newValueCnpj = cep.replace(/[^0-9]/g, "");

    api
      .get(`https://viacep.com.br/ws/${newValueCnpj}/json/`)
      .then((response) => {
        const { data } = response;

        const obj = [
          { name: "address", value: data.logradouro },
          { name: "district", value: data.bairro },
          { name: "city", value: data.localidade },
          { name: "state", value: data.uf },
        ];

        obj.forEach(({ name, value }) => setValue(name, value));
      })
      .catch((error) => {
        console.error(error);
        setIsAlert((prevAlert) => ({
          ...prevAlert,
          open: true,
          message: "Não foi possível carregar CEP, verifique os dados!",
          status: "error",
        }));
      });
  };

  React.useEffect(() => {
    api
      .get(routeProfile)
      .then((response) => {
        const { user } = response.data;

        //Trocando todos os values "null" por "vazio"
        Object.keys(user).forEach((value) => {
          if (user[value] === null) user[value] = "";
        });

        const obj = [
          { name: "zipCode", value: formatToCEP(user.cep) },
          { name: "address", value: user.logradouro },
          { name: "number", value: user.numero },
          { name: "complement", value: user.complemento },
          { name: "district", value: user.bairro },
          { name: "city", value: user.cidade },
          { name: "state", value: user.estado },
        ];

        obj.forEach(({ name, value }) => setValue(name, value));
      })
      .catch((error) => {
        handlingErrors(error, setIsAlert);
      })
      .finally(() => setIsLoading(false));
  }, [isSaved, setValue]);

  const onSubmit = async (data) => {
    const { zipCode, address, number, complement, district, city, state } =
      data;

    api
      .post(routeEditAddress, {
        cep: zipCode,
        endereco: address,
        numero: number,
        complemento: complement,
        bairro: district,
        cidade: city,
        estado: state,
      })
      .then((response) => {
        handlingSuccess(response, setIsAlert);
        setIsSaved(true);
      })
      .catch((error) => {
        handlingErrors(error, setIsAlert);
      });
  };

  return isLoading ? (
    <Box display="flex" height="100vh">
      <Loading />
    </Box>
  ) : (
    <Box display="flex" flexDirection="column">
      <Helmet>
        <title>Editar endereço | Originador </title>
      </Helmet>
      {isAlert.open && (
        <Alert
          component="temporary"
          open={isAlert.open}
          severity={isAlert.status}
          message={isAlert.message}
          onClose={handleCloseSnackbar}
        />
      )}
      <Box display="flex" justifyContent="initial">
        <Typography fontWeight="bold">Editar endereço</Typography>
      </Box>
      <Paper width="100%" mt={4}>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" my={2}>
            <Box width="100%" maxWidth="800px">
              <Box display={isMobile ? "block" : "flex"}>
                <Controls.InputMask
                  name="zipCode"
                  label="CEP"
                  placeholder="Digite seu cep"
                  size="medium"
                  className={classes.input}
                  control={control}
                  onBlur={(event) => handleGetAddress(event.target.value)}
                  required
                  mask="99999-999"
                />
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                flexDirection={isMobile ? "column" : "row"}
              >
                <Controls.Input
                  name="address"
                  label="Endereço"
                  placeholder="Digite seu endereço"
                  size="medium"
                  control={control}
                  className={classes.input}
                  required
                />
                <Controls.Input
                  name="number"
                  label="Numero"
                  placeholder="Digite o numero"
                  size="medium"
                  control={control}
                  className={classes.input}
                  required
                />
                <Controls.Input
                  name="complement"
                  label="Complemento"
                  placeholder="Digite o complemento"
                  size="medium"
                  control={control}
                  className={classes.input}
                />
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                flexDirection={isMobile ? "column" : "row"}
              >
                <Controls.Input
                  name="district"
                  label="Bairro"
                  placeholder="Digite seu bairro"
                  size="medium"
                  control={control}
                  className={classes.input}
                  required
                />
                <Controls.Input
                  name="city"
                  label="Cidade"
                  placeholder="Digite sua cidade"
                  size="medium"
                  control={control}
                  className={classes.input}
                  required
                />
                <Controls.Select
                  name="state"
                  label="Estado"
                  placeholder="Digite seu estado"
                  size="medium"
                  control={control}
                  options={stateOptions}
                  className={classes.input}
                  required
                />
              </Box>
              <Box display="flex" mt={2}>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  className={classes.buttonSubmit}
                >
                  Salvar alterações
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </Paper>
    </Box>
  );
};
