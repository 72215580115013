import React from "react";
import { Typography, Box, Tooltip } from "@material-ui/core";
import { useStyles } from "./style";

export const TypographyComponent = (props) => {
  const classes = useStyles();
  return props.ellipsis ? (
    <Tooltip title={props.children} placement="top">
      <Typography
        variant={props.variant || "body1"}
        {...props}
        className={`${classes.input} ${props.ellipsis ? classes.ellipsis : ""}`}
      >
        <Box
          component="span"
          fontWeight={props.fontWeight || "fontWeightMedium"}
          textAlign={props.textAlign || "center"}
          fontSize={props.fontSize}
          fontStyle={props.fontStyle || "normal"}
          fontFamily={props.fontFamily || "fontFamily"}
          letterSpacing={props.letterSpacing}
          lineHeight={props.lineHeight || "normal"}
          width="100%"
        >
          {props.children}
        </Box>
      </Typography>
    </Tooltip>
  ) : (
    <Typography
      variant={props.variant || "body1"}
      {...props}
    >
      <Box
        component="span"
        fontWeight={props.fontWeight || "fontWeightMedium"}
        textAlign={props.textAlign || "center"}
        fontSize={props.fontSize}
        fontStyle={props.fontStyle || "normal"}
        fontFamily={props.fontFamily || "fontFamily"}
        letterSpacing={props.letterSpacing}
        lineHeight={props.lineHeight || "normal"}
        width="100%"
      >
        {props.children}
      </Box>
    </Typography>
  );
};
