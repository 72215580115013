import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isAuthenticated } from "./services/auth";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          rest.permission ? (
            ProtectedRoute(Component, props, rest)
          ) : (
            DefaultRoute(Component, props, rest)
          )
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
};

const ProtectedRoute = (Component, props, rest) => {
  if (
    rest.permission === "admin" &&
    sessionStorage.getItem("access_type").includes("admin")
  ) {
    return Component ? <Component {...props} /> : rest.render(props);
  } else {
    return <Redirect to="/customers" />;
  }
};

const DefaultRoute = (Component, props, rest) => {
  return Component ? <Component {...props} /> : rest.render(props);
};
